import React, { useState } from 'react';
import Router from './Router';
import './styles/index.scss';
import { ActiveButtonContext } from './utils/context/ActiveButtonContext';
import { ButtonType } from "../typings/types";
import { Provider } from 'react-redux';
import store from './utils/redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './utils/redux/Store';
import Initializer from './utils/Initializer';

function App() {
    const [activeButton, setActiveButton] = useState<ButtonType>('orders');

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                    <ActiveButtonContext.Provider value={{ activeButton, setActiveButton }}>

                        <Initializer>

                            <Router />

                        </Initializer>

                    </ActiveButtonContext.Provider>

            </PersistGate>
        </Provider>
    );
}

export default App;
