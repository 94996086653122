import React, {useEffect, useState} from "react";
import styles from "./OrdersSingle.module.scss";
import {getStatus} from "../../../utils/functions/statuses/GetStatusesOrders";
import axiosInstance from "../../../utils/axios/AxiosConfig";
import {useSelector} from "react-redux";
import {RootState} from "../../../utils/redux/Store";
import LoaderSmall from "../../../components/loaders/LoaderSmall";
import {useNavigate, useParams} from "react-router-dom";
import {transformOrderDataDtl, transformOrderDataRel} from "../../../utils/functions/DataTransformers"
import {OrderDetails, OrderShipments, OrderShipmentDtl, DropdownState} from "../../../../typings/types";
import OrderRelTable from "../../../utils/tables/OrderRelTable";
import OrderShipmentDtlTable from "../../../utils/tables/OrderShipmentDtlTable";
import {generateTrackingLink} from "../../../utils/functions/TrackingLinks";
import SearchHeader from "../../../components/SearchHeader";


const OrdersSingleDataTable: React.FC = () => {
    // const location = useLocation();
    const {orderNum} = useParams<{ orderNum: string }>();


    const token = useSelector((state: RootState) => state.auth.token);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [orderDetailsData, setOrderDetailsData] = useState<OrderDetails[]>([]);

    const [orderRelData, setOrderRelData] = useState<OrderDetails[]>([]);

    const [orderShipmentData, setOrderShipmentData] = useState<OrderShipments[]>([]);

    const [shipmentDtlLoading, setShipmentDtlLoading] = useState(true);
    const [shipmentDtlRelLoading, setShipmentDtlRelLoading] = useState(true);
    const [orderShipmentDtlData, setOrderShipmentDtlData] = useState<OrderShipmentDtl[]>([]);

    const [activeTab, setActiveTab] = useState('Line');


    // const [shipDtlPackNum, setShipDtlPackNum] = useState<number | null>(null);
    const [shipDtlPackNum, setShipDtlPackNum] = useState<number[]>([]);


    const [search, setSearch] = useState<string>('');

    const [openDropdowns, setOpenDropdowns] = useState<DropdownState>({});
    const [openDropdownsShipment, setOpenDropdownsShipment] = useState<DropdownState>({});

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const toggleDropdown = (id: string) => {
        const currentStatus = openDropdowns[id] || false;
        setOpenDropdowns({
            ...openDropdowns,
            [id]: !currentStatus
        });
    };

    const toggleDropdownShipment = (id: string) => {
        const currentStatus = openDropdownsShipment[id] || false;
        setOpenDropdownsShipment({
            ...openDropdownsShipment,
            [id]: !currentStatus
        });
    };

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axiosInstance.get(`/orders/details/${orderNum}`, {
                    headers: {
                        'Auth-Token': token
                    }
                });

                if (response.status === 200) {
                    setOrderDetailsData(transformOrderDataDtl(response.data.value));
                    console.log('details data:', response.data.value);
                }
            } catch (error) {
                console.error("Failed to fetch order details:", error);
            } finally {
                setDetailsLoading(false);
            }
        };

        fetchOrder().catch(e => {
            console.error("Unhandled promise rejection in fetchOrder:", e);
        });
    }, [token, orderNum]);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axiosInstance.get(`/orders/rel/${orderNum}`, {
                    headers: {
                        'Auth-Token': token
                    }
                });

                if (response.status === 200) {
                    setOrderRelData(transformOrderDataRel(response.data.value));
                    console.log('rel data:', response.data.value);
                }
            } catch (error) {
                console.error("Failed to fetch order rel:", error);
            } finally {
                console.log('Finished rel loading.');
                setShipmentDtlRelLoading(false);
            }
        };

        fetchOrder().catch(e => {
            console.error("Unhandled promise rejection in fetchOrder:", e);
        });
    }, [token, orderNum]);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axiosInstance.get(`/orders/shipments/${orderNum}`, {
                    headers: {
                        'Auth-Token': token
                    }
                });

                if (response.status === 200) {
                    setOrderShipmentData(response.data.value);
                    console.log('shipment data:', response.data.value);

                    if (response.data.value.length > 0) {
                        // const packNum = response.data.value[0].ShipDtl_PackNum;
                        // setShipDtlPackNum(packNum);
                        const packNums = new Set<number>(response.data.value.map((item: {
                            ShipDtl_PackNum: any;
                        }) => item.ShipDtl_PackNum));
                        setShipDtlPackNum(Array.from(packNums));
                    } else {
                        setShipDtlPackNum([]);
                        setShipmentDtlLoading(false);
                    }
                }
            } catch (error) {
                console.error("Failed to fetch order shipment:", error);
            } finally {
                // setShipmentLoading(false);
            }
        };

        fetchOrder().catch(e => {
            console.error("Unhandled promise rejection in fetchOrder:", e);
        });
    }, [token, orderNum]);


    useEffect(() => {
        if (!shipDtlPackNum) return;

        const fetchAllShipmentDetails = async () => {
            setShipmentDtlLoading(true);
            const allShipmentDetails = [];

            for (const packNum of shipDtlPackNum) {
                try {
                    const response = await axiosInstance.get(`/orders/shipmentdtl/${packNum}`, {
                        headers: {
                            'Auth-Token': token
                        }
                    });

                    if (response.status === 200) {
                        allShipmentDetails.push(response.data.value);
                    }
                } catch (error) {
                    console.error(`Failed to fetch shipment details for pack number ${packNum}:`, error);
                }
            }

            setOrderShipmentDtlData(allShipmentDetails.flat());

            setShipmentDtlLoading(false);
        };

        fetchAllShipmentDetails().catch(e => {
            console.error("Unhandled promise rejection in fetchAllShipmentDetails:", e);
        });
    }, [token, shipDtlPackNum]);


    const filterDataForOrderRel = (orderDetail: { TopOrderDtl_OrderLine: number; }) => {
        return orderRelData.filter(rel => rel.TopOrderDtl_OrderLine === orderDetail.TopOrderDtl_OrderLine);
    };

    const filterDataForOrderShipment = (packNum: any) => {
        return orderShipmentDtlData.filter(dtl => dtl.ShipDtl_PackNum === packNum);
    };
    const getMostRecentDate = (dates: Array<string>) => {
        let mostRecentDate = null;

        for (const date of dates) {
            if (date === 'N/A') {
                return 'N/A';
            }
            const currentDate = new Date(date);
            if (mostRecentDate === null || currentDate > mostRecentDate) {
                mostRecentDate = currentDate;
            }
        }
        return mostRecentDate ? mostRecentDate.toISOString().split('T')[0] : 'N/A';
    };

    const trackingNumber = orderDetailsData[0]?.Calculated_TrackList;
    const generateCarrierUrl = (number: string) => {
        const hasUppercase = /[A-Z]/.test(number);
        return hasUppercase
            ? `https://www.ups.com/track?track=yes&trackNums=${number}`
            : `https://www.fedex.com/fedextrack/?trknbr=${number}`;
    }

    const trackingNumbersArray = trackingNumber ? trackingNumber.split(',').map(num => num.trim()) : [];

    const statusDetails = getStatus(orderDetailsData[0]?.Calculated_OrdStatus);

    const navigate = useNavigate();
    return (
        <div className={styles.ordersSingle}>
            <SearchHeader search={search} setSearch={setSearch}/>


            {detailsLoading || shipmentDtlLoading || shipmentDtlRelLoading ? (
                <LoaderSmall/>
            ) : (
                <>


                    <div className={styles.singleOrdersHeadContainer}>
                        <div className={styles.innerContent}>

                            <div className={styles.buttonContainer}>
                                <button className={styles.backContainer} onClick={() => navigate('/orders')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5.21903 7.33327H13.3334V8.6666H5.21903L8.79501 12.2425L7.85221 13.1853L2.66675 7.99993L7.85221 2.81445L8.79501 3.75726L5.21903 7.33327Z" fill="white"/>
                                    </svg>
                                    Back
                                </button>
                            </div>

                            <div className={styles.top}>
                                <div>
                                    <h2>Order #{orderNum}</h2>
                                    <h2>PO Number: {orderDetailsData[0]?.OrderHed_PONum}</h2>
                                </div>
                                <div className={`${styles.statusContainer} ${styles[statusDetails.color]}`}>
                                    <span className={styles.statusIndicator}></span>
                                    <p>{statusDetails.text}</p>
                                </div>
                            </div>

                            <div className={styles.bot}>
                                <div className={styles.calendarContainer}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                         fill="none" className={styles.calendar}>
                                        <path
                                            d="M5.99992 1.16667V2.5H9.99992V1.16667H11.3333V2.5H13.9999C14.3681 2.5 14.6666 2.79848 14.6666 3.16667V13.8333C14.6666 14.2015 14.3681 14.5 13.9999 14.5H1.99992C1.63173 14.5 1.33325 14.2015 1.33325 13.8333V3.16667C1.33325 2.79848 1.63173 2.5 1.99992 2.5H4.66658V1.16667H5.99992ZM13.3333 7.83333H2.66659V13.1667H13.3333V7.83333ZM4.66658 3.83333H2.66659V6.5H13.3333V3.83333H11.3333V5.16667H9.99992V3.83333H5.99992V5.16667H4.66658V3.83333Z"
                                            fill="white"/>
                                    </svg>
                                    <p>{orderDetailsData[0]?.OrderHed_OrderDate}</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="17" viewBox="0 0 2 17"
                                     fill="none"
                                     className={styles.divider}>
                                    <path d="M1 0V17" stroke="white"/>
                                </svg>
                                <p>Tracking Number: </p>
                                {
                                    trackingNumbersArray.length > 0 && trackingNumbersArray.map((num, index) => (
                                        <a key={index} href={generateCarrierUrl(num)} target="_blank"
                                           rel="noopener noreferrer" className={styles.accented}>
                                            {num}{index !== trackingNumbersArray.length - 1 ? ', ' : ''}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>

                    </div>


                    <div className={styles.ordersSingleWrapper}>

                        <div className={styles.orderTabs}>
                            <div
                                className={`${styles.tab} ${activeTab === 'Line' ? styles.activeTab : ''}`}
                                onClick={() => handleTabClick('Line')}
                            >
                                Line Items
                            </div>
                            <div
                                className={`${styles.tab} ${activeTab === 'Shipment' ? styles.activeTab : ''}`}
                                onClick={() => handleTabClick('Shipment')}
                            >
                                Shipments
                            </div>
                        </div>

                        {activeTab === 'Line' && (
                        <div>
                            {orderDetailsData.map((orderDetail) => {
                                const statusDetails = getStatus(orderDetail.Calculated_LineStatus);
                                const filteredData = filterDataForOrderRel(orderDetail);
                                const dueDates: Array<string> = filteredData.map(item => item.Calculated_DueDateAdj);
                                const mostRecentDueDate = getMostRecentDate(dueDates);

                                return (
                                    <div key={orderDetail.RowIdent}
                                         className={`${styles.orderDetailsContainer} ${openDropdowns[orderDetail.RowIdent] ? styles.toggled : ''}`}>


                                        <div className={styles.dropdownOuter}>


                                            <div className={styles.dropdownInner}>

                                                <button onClick={() => toggleDropdown(orderDetail.RowIdent)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none"
                                                         className={openDropdowns[orderDetail.RowIdent] ? styles.rotated : ""}>
                                                        <path
                                                            d="M13.1714 12.0003L8.22168 7.0505L9.63589 5.6363L15.9999 12.0003L9.63589 18.3643L8.22168 16.95L13.1714 12.0003Z"
                                                            fill="black"/>
                                                    </svg>
                                                </button>

                                                <div className={styles.contents}>
                                                    <p>Line Number: <span>{orderDetail.TopOrderDtl_OrderLine}</span></p>
                                                    <p>Part Number: <span
                                                        className={styles.uppercase}>{orderDetail.TopOrderDtl_PartNum}</span>
                                                    </p>
                                                    <p>Customer Part Number: <span
                                                        className={styles.uppercase}>{orderDetail.TopOrderDtl_XPartNum}</span>
                                                    </p>
                                                    <p>Quantity: <span>{orderDetail.TopOrderDtl_OrderQty}</span></p>
                                                    <p>Part Description: <span>{orderDetail.TopOrderDtl_LineDesc}</span>
                                                    </p>
                                                    <p>Due Date: <span>{mostRecentDueDate}</span>
                                                    </p>
                                                </div>

                                                <div
                                                    className={`${styles.statusContainer} ${styles[statusDetails.color]}`}>
                                                    <span className={styles.statusIndicator}></span>
                                                    <p>{statusDetails.text}</p>
                                                </div>


                                            </div>

                                            <div
                                                className={`${styles.dropdownAnimation} ${openDropdowns[orderDetail.RowIdent] ? styles.open : ''}`}>
                                                <OrderRelTable data={filteredData}/>
                                            </div>

                                        </div>


                                    </div>
                                );
                            })}

                        </div>
                        )}

                        {activeTab === 'Shipment' && (
                        <div className={styles.shipmentsContainer}>

                            {/*<div*/}
                            {/*    className={`${styles.shipmentsHeader} ${orderShipmentData.length === 0 ? styles.roundedCorners : ''}`}>*/}
                            {/*    <h1>*/}
                            {/*        {orderShipmentData.length > 0 ? "Shipments" : "Shipments - Not Yet Available"}*/}
                            {/*    </h1>*/}
                            {/*</div>*/}

                            {
                                orderShipmentData.length === 0 && (
                                    <div className={`${styles.shipmentsHeader} ${styles.roundedCorners}`}>
                                        <h1>Shipments - Not Yet Available</h1>
                                    </div>
                                )
                            }

                            {shipDtlPackNum.map((packNum) => {
                                const shipmentDetails = filterDataForOrderShipment(packNum);
                                const shipment = orderShipmentData.find(item => item.ShipDtl_PackNum === packNum);
                                if (!shipment) return null;
                                return (
                                    <div
                                        key={packNum}
                                        className={`${styles.orderDetailsContainer + ' ' + styles.shipment} ${openDropdownsShipment[shipment.RowIdent] ? styles.toggled : ''}`}>

                                        <div className={styles.dropdownOuter}>


                                            <div className={styles.dropdownInner + ' ' + styles.shipment}>

                                                <button className={styles.shipment}
                                                        onClick={() => toggleDropdownShipment(shipment.RowIdent)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none"
                                                         className={openDropdownsShipment[shipment.RowIdent] ? styles.rotated : ""}>
                                                        <path
                                                            d="M13.1714 12.0003L8.22168 7.0505L9.63589 5.6363L15.9999 12.0003L9.63589 18.3643L8.22168 16.95L13.1714 12.0003Z"
                                                            fill="black"/>
                                                    </svg>
                                                </button>

                                                <div className={styles.contents + ' ' + styles.shipment}>
                                                    <p>Carrier: <span>{shipment.ShipVia_Description}</span></p>
                                                    <p>Ship
                                                        Date: <span>{shipment.ShipHead_ShipDate.split('T')[0]}</span>
                                                    </p>
                                                    <p>Tracking Number: <span
                                                        className={styles.uppercase}>{shipment.ShipHead_TrackingNumber}</span>
                                                    </p>
                                                </div>

                                                <a className={styles.trackingInfo}
                                                   href={generateTrackingLink(shipment.ShipVia_Description, shipment.ShipHead_TrackingNumber)}
                                                   rel="noopener noreferrer"
                                                   target={"_blank"}>
                                                    Tracking Info
                                                </a>


                                            </div>

                                            <div
                                                className={`${styles.dropdownAnimation} ${openDropdownsShipment[shipment.RowIdent] ? styles.open : ''}`}>
                                                <OrderShipmentDtlTable data={shipmentDetails}/>
                                            </div>

                                        </div>


                                    </div>
                                );
                            })}

                        </div>
                        )}
                    </div>
                </>
            )}

            {/*</div>*/}
        </div>
    );
};

export default OrdersSingleDataTable;
