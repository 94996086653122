import React, {useCallback, useMemo, useEffect, useRef, useState} from 'react';
import styles from './DataTable.module.scss'
import OrderTableNav from "../../../components/tablenavs/OrderTableNav";
import {OrderProps} from '../../../../typings/types';
import {useSelector} from "react-redux";
import axiosInstance from '../../../utils/axios/AxiosConfig';
import {RootState} from "../../../utils/redux/Store";
import Loader from "../../../components/loaders/Loader";
import Pagination from "../../../components/pagination/Pagination";
import {transformOrderData} from "../../../utils/functions/DataTransformers"
import OrdersTable from "../../../utils/tables/OrdersTable";
import {useLocation, useNavigate} from "react-router-dom";
import getUpdatedUrlParams from "../../../utils/functions/GetUpdatedUrlParams";
import SearchHeader from "../../../components/SearchHeader";

const OrdersDataTable: React.FC = () => {
    // const abortController = useRef<AbortController | null>(null);
    // const searchDebounce = useRef<ReturnType<typeof setTimeout> | null>(null);


    //Helpers
    const token = useSelector((state: RootState) => state.auth.token);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const navigate = useNavigate();


    const currentPage = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('page') ? parseInt(queryParams.get('page')!, 10) : 1;
    }, [location.search]);

    const selectedStatus = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('status') || '';
    }, [location.search]);

    const searchQuery = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('search') || '';
    }, [location.search]);

    const selectedButton = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('button') || '';
    }, [location.search]);

    //States
    const [orderData, setOrderData] = useState<OrderProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchError, setSearchError] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [search, setSearch] = useState<string>(searchQuery);


    //Pagination
    const [totalRecords, setTotalRecords] = useState(undefined);
    const recordsPerPage = 20;
    const totalPages = Math.ceil((totalRecords || 0) / recordsPerPage);


    const fetchOrders = useCallback(async () => {
        // abortController.current?.abort()
        // abortController.current = new AbortController()

        let filterParam = '';

        if (selectedStatus) {
            filterParam = `&$filter=Calculated_OrdStatus eq '${selectedStatus}'`;
        }

        if (selectedButton) {
            if (selectedButton === 'Open') {
                filterParam += filterParam ? ' and OrderHed_OpenOrder eq true' : '&$filter=OrderHed_OpenOrder eq true';
            } else if (selectedButton === 'Closed') {
                filterParam += filterParam ? ' and OrderHed_OpenOrder eq false' : '&$filter=OrderHed_OpenOrder eq false';
            }
        }

        if (searchQuery) {
            const searchFilters = [
                `OrderHed_PONum eq '${searchQuery}'`,
                `OrderHed_OrderNum eq ${searchQuery}`
            ];

            const combinedSearchFilter = searchFilters.join(' or ');
            filterParam += filterParam ? ` and (${combinedSearchFilter})` : `&$filter=(${combinedSearchFilter})`;
        }

        try {
            const response = await axiosInstance.get(`/orders?$top=${recordsPerPage}&$skip=${(currentPage - 1) * recordsPerPage}${filterParam}&$count=true`, {
                headers: {
                    'Auth-Token': token
                },
                // signal: abortController.current.signal
            });

            if (response.status === 200) {
                setTotalRecords(response.data["@odata.count"]);
                setOrderData(transformOrderData(response.data.value));
                console.log(response.data.value);
            }
            if (response.data.value.length === 0) {
                setSearchError("No results found. ");
            }
        } catch (error) {
            // @ts-ignore
            if (error?.code !== "ERR_CANCELED") {
                if (searchQuery) {
                    setSearchError("No results found for your search. ");
                } else {
                    setErrorMessage("Something went wrong.")
                    console.error("Failed to fetch orders:", error);
                }
            }
        } finally {
            setLoading(false);
        }
    }, [currentPage, searchQuery, selectedButton, selectedStatus, token])


    useEffect(() => {
        setLoading(true);
        setSearchError(null);
        setErrorMessage(null);

        fetchOrders().catch(e => {
            console.error("Unhandled promise rejection in fetchOrder:", e);
        });

    }, [fetchOrders, location.search]);


    const handlePaginationClick = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const refreshData = () => {
        setSearchError(null);
        setErrorMessage(null);
        // setLoading(true);
        navigate('/orders');
    };

    // const onSearch = useCallback(() => {
    //     if (searchDebounce.current) {
    //         clearTimeout(searchDebounce.current)
    //     }
    //
    //     searchDebounce.current = setTimeout(() => {
    //         const queryParams = getUpdatedUrlParams(location.search, 'search', search, ['page']);
    //         navigate(`?${queryParams.toString()}`, {replace: true});
    //     }, 500)
    //
    // }, [search])
    //
    // useEffect(() => {
    //     onSearch()
    // }, [onSearch])

    return (
        <div className={styles.DataTable} ref={tableRef}>
            <SearchHeader search={search} setSearch={setSearch}/>
            <div className={styles.ordersWrapper}>
                {loading ? (
                    <Loader/>
                ) : searchError ? (
                    <div className={styles.error}>{searchError}
                        <span>Click <button onClick={refreshData}>here</button> to reload.</span>
                    </div>
                ) : errorMessage ? (
                    <div className={styles.error}>{errorMessage}
                        <span>Click <button onClick={refreshData}>here</button> to reload.</span>
                    </div>
                ) : (
                    <>

                        <OrderTableNav orderCount={totalRecords}/>
                        <div className={styles.tableWrapper}>
                            <OrdersTable data={orderData} recordsPerPage={recordsPerPage}/>
                        </div>

                        <Pagination
                            totalPages={totalPages}
                            onPageChange={(newPage) => {
                                const queryParams = getUpdatedUrlParams(location.search, 'page', newPage.toString());
                                navigate(`?${queryParams.toString()}`, {replace: true});
                            }}
                            onPaginationClick={handlePaginationClick}
                        />

                    </>
                )}

            </div>
        </div>
    );
};

export default OrdersDataTable;
