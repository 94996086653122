import styles from "../../screens/datatables/Invoices/InvoicesDataTable.module.scss";
import downloadPdf from "./InvoicesTable";
import download from '../../assets/images/download.svg';
import React, {useEffect, useMemo, useState} from "react";
import {InvoicesTableMobileProps} from "../../../typings/types";
import {useSelector} from "react-redux";
import {RootState} from "../redux/Store";
import axiosInstance from "../axios/AxiosConfig";


const InvoicesTableMobile: React.FC<InvoicesTableMobileProps> = ({invoice, isOpen, toggleOpen, invoiceDtlData}) => {

    const flattenedData = useMemo(() => invoiceDtlData.flat(), [invoiceDtlData]);
    const relevantDetails = useMemo(() =>
            flattenedData.filter((detail: { InvcHead_InvoiceNum: number; }) => detail.InvcHead_InvoiceNum === invoice.InvcHead_InvoiceNum),
        [flattenedData, invoice.InvcHead_InvoiceNum]
    );
    const [downloading, setDownloading] = useState('');
    const [finishedDownloading, setFinishedDownloading] = useState('');
    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        const id = downloading === '' ? finishedDownloading : downloading;
        const downloadIcon = document.getElementById(id+"-icon");
        const spinner = document.getElementById(id+"-spinner");
        if (downloadIcon && spinner) {
            if (downloading !== '') {
                downloadIcon.style.display = 'none';
                spinner.style.display = 'block';
            } else {
                downloadIcon.style.display = 'block';
                spinner.style.display = 'none';
            }
        }
    }, [downloading, finishedDownloading]);


    const handleDownloadClick = async (pdfKey: string, invoiceNum: number | string) => {
        if (downloading !== invoiceNum.toString()) {
            setDownloading(invoiceNum.toString());
            await downloadPdf(pdfKey, invoiceNum);
            setDownloading('');
            setFinishedDownloading(invoiceNum.toString());
        }
    }

    const downloadPdf = async (pdfKey: string, invoiceNum: number | string) => {
        try {
            console.log("download pdf");
            const response = await axiosInstance.get(`/download/invoice/${invoiceNum}?x=${pdfKey}`, {
                headers: {
                    'Auth-Token': token
                },
                responseType: 'blob'
            });

            if (response.status !== 200) {
                console.error('Failed to download pdf');
                return;
            }

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoiceNum}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download pdf', error);
        }
    }


    return (
        <div className={styles.mobileCardContainer} style={{marginTop: '24px'}}>

            <div className={styles.mobileCardContainerTop}>

                <div className={styles.mobileArrowContainer}>
                    <button onClick={toggleOpen}>
                        <svg className={isOpen ? styles.rotated : ""}
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none">
                            <path
                                d="M13.1714 12.0003L8.22168 7.0505L9.63589 5.6363L15.9999 12.0003L9.63589 18.3643L8.22168 16.95L13.1714 12.0003Z"
                                fill="black"/>
                        </svg>
                    </button>
                    <p className={styles.ref}>{invoice.InvcHead_InvoiceNum}</p>
                    <button className={styles.downloadButton}
                        onClick={async () => {
                        handleDownloadClick(invoice.DownloadKey, invoice.InvcHead_InvoiceNum);
                    }}>
                        <img id={invoice.InvcHead_InvoiceNum + "-icon"} src={download} alt={"Download PDF"}/>
                        <div id={invoice.InvcHead_InvoiceNum + "-spinner"} className={styles.spinner} style={{display: 'none'}}></div>
                    </button>
                </div>

            </div>


            <div className={styles.mobileCardContent}>
                <div className={styles.columns}>
                    <div className={styles.item}>
                        <p className={styles.header}>Due Date</p>
                        <p className={styles.value}>{invoice.InvcHead_InvoiceDate}</p>
                    </div>
                    {/*<div className={styles.item}>*/}
                    {/*    <p className={styles.header}>Terms</p>*/}
                    {/*    <p className={styles.value}>{invoice.Terms_Description}</p>*/}
                    {/*</div>*/}
                    <div className={styles.item}>
                        <p className={styles.header}>PO Num</p>
                        <p className={styles.value}>{invoice.InvcHead_PONum}</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.header}>Amount</p>
                        <p className={styles.value}>${invoice.InvcHead_InvoiceAmt}</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.header}>Balance</p>
                        <p className={styles.value}>${invoice.InvcHead_InvoiceBal}</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.header}>Aging</p>
                        <p className={styles.value} style={{ color: invoice.Calculated_Aging.includes('Over') ? 'red' : 'inherit' }}>
                            {invoice.Calculated_Aging}
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${styles.mobileDropDownContent} ${isOpen ? styles.open : ""}`}>
                {relevantDetails.map((detail: any, index: React.Key | null | undefined) => (
                    <React.Fragment key={`detail-${index}`}>
                        <div key={`top-${index}`} className={styles.top}>
                            <p>{detail.InvcHead_PONum}</p>
                        </div>
                        <div key={`inner-${index}`} className={styles.inner}>
                            <div className={styles.columns}>
                                <div className={styles.item}>
                                    <p className={styles.header}>Line Number</p>
                                    <p className={styles.value}>{detail.TopInvcDtl_InvoiceLine}</p>
                                </div>
                                <div className={styles.item}>
                                    <p className={styles.header}>Part Number</p>
                                    <p className={styles.value}>{detail.TopInvcDtl_PartNum}</p>
                                </div>
                                <div className={styles.item}>
                                    <p className={styles.header}>Release Number</p>
                                    <p className={styles.value}>{detail.TopInvcDtl_OrderRelNum}</p>
                                </div>
                                <div className={styles.item}>
                                    <p className={styles.header}>Quantity Shipped</p>
                                    <p className={styles.value}>{detail.TopInvcDtl_SellingShipQty}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>


        </div>
    )
}

export default InvoicesTableMobile;
