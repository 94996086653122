export const getStatus = (status: string | boolean): {color: string, text: string} => {
    switch (status) {
        case true:
            return {color: 'green', text: 'Open'};
        case false:
            return {color: 'gray', text: 'Closed'};
        default:
            return {color: '', text: status};
    }
}
