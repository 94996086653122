export const getStatus = (status: string): {color: string, text: string} => {
    switch (status) {
        // case true:
        //     return {color: 'yellow', text: 'Open'};
        // case false:
        //     return {color: 'green', text: 'Not Open'};
        case 'Open':
            return {color: 'orange', text: 'Open'};
        case 'Shipped':
            return {color: 'yellow', text: 'Shipped'};
        case 'Partially Shipped':
            return {color: 'gray', text: 'Partially Shipped'};
        case 'Invoiced':
            return {color: 'green', text: 'Invoiced'};
        case 'Partially Invoiced':
            return {color: 'blue', text: 'Partially Invoiced'};
        default:
            return {color: '', text: status};
    }
}
