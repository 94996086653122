import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from "./TableNav.module.scss";
import {InvoiceTableNavProps} from '../../../typings/types';
import InvoiceTableDropDown from "./InvoiceTableDropDown";
import {useLocation, useNavigate} from "react-router-dom";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";

const InvoiceTableNav: React.FC<InvoiceTableNavProps> = ({orderCount}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedButton = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('button') || '';
    }, [location.search]);

    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
            buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    const handleButtonClick = (status: '' | 'Open' | 'Closed') => {
        const queryParams = getUpdatedUrlParams(location.search, 'button', status, ['page', 'status']);
        navigate(`?${queryParams.toString()}`, {replace: true});
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.orderTableNav}>
            <div className={styles.orderTableNavBarContainer}>
                <div className={styles.textContainer}>
                    <h1>Invoices <span>{orderCount}</span></h1>
                </div>
                <div className={styles.filtersContainer}>
                    <div className={styles.textBtnContainer}>
                        <button
                            className={selectedButton === '' ? styles.toggled : ''}
                            onClick={() => handleButtonClick('')}
                        >
                            All
                        </button>
                        <button
                            className={selectedButton === 'Open' ? styles.toggled : ''}
                            onClick={() => handleButtonClick('Open')}
                        >
                            Open
                        </button>
                        <button
                            className={selectedButton === 'Closed' ? styles.toggled : ''}
                            onClick={() => handleButtonClick('Closed')}
                        >
                            Closed
                        </button>
                    </div>
                    <button ref={buttonRef} className={styles.filterBtn} onClick={() => setShowDropdown(!showDropdown)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M8.22757 24C8.77668 22.4464 10.2583 21.3333 12 21.3333C13.7416 21.3333 15.2233 22.4464 15.7724 24H29.3333V26.6666H15.7724C15.2233 28.2202 13.7416 29.3333 12 29.3333C10.2583 29.3333 8.77668 28.2202 8.22757 26.6666H2.66663V24H8.22757ZM16.2276 14.6666C16.7766 13.113 18.2584 12 20 12C21.7416 12 23.2233 13.113 23.7724 14.6666H29.3333V17.3333H23.7724C23.2233 18.8869 21.7416 20 20 20C18.2584 20 16.7766 18.8869 16.2276 17.3333H2.66663V14.6666H16.2276ZM8.22757 5.33329C8.77668 3.77971 10.2583 2.66663 12 2.66663C13.7416 2.66663 15.2233 3.77971 15.7724 5.33329H29.3333V7.99996H15.7724C15.2233 9.55355 13.7416 10.6666 12 10.6666C10.2583 10.6666 8.77668 9.55355 8.22757 7.99996H2.66663V5.33329H8.22757ZM12 7.99996C12.7363 7.99996 13.3333 7.403 13.3333 6.66663C13.3333 5.93025 12.7363 5.33329 12 5.33329C11.2636 5.33329 10.6666 5.93025 10.6666 6.66663C10.6666 7.403 11.2636 7.99996 12 7.99996ZM20 17.3333C20.7364 17.3333 21.3333 16.7364 21.3333 16C21.3333 15.2636 20.7364 14.6666 20 14.6666C19.2636 14.6666 18.6666 15.2636 18.6666 16C18.6666 16.7364 19.2636 17.3333 20 17.3333ZM12 26.6666C12.7363 26.6666 13.3333 26.0697 13.3333 25.3333C13.3333 24.5969 12.7363 24 12 24C11.2636 24 10.6666 24.5969 10.6666 25.3333C10.6666 26.0697 11.2636 26.6666 12 26.6666Z"
                                fill="#111111"/>
                        </svg>
                    </button>
                    {showDropdown &&
                        <InvoiceTableDropDown
                            closeDropdown={() => setShowDropdown(false)}
                            ref={dropdownRef}
                            />}
                </div>
            </div>
        </div>
    );
};

export default InvoiceTableNav;
