import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './Sidebar.module.scss';
import logo from '../../assets/images/logo.svg';
import {useActiveButton} from '../../utils/context/ActiveButtonContext';
import MobileMenu from './MobileMenu';
import MobileSearchBar from './MobileSearchBar';
import Hamburger from 'hamburger-react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserInfo, logout} from "../../utils/redux/AuthSlice";
import {purgeStoredState} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useLocation } from 'react-router-dom';
import {AppDispatch, RootState} from "../../utils/redux/Store";


const Sidebar = () => {
    const {activeButton, setActiveButton} = useActiveButton();
    const token = useSelector((state: RootState) => state.auth.token);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const dispatch = useDispatch<AppDispatch>();



    const [mobileMenu, setMobileMenu] = useState(false);
    const [searchBar, setSearchBar] = useState(false);


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/orders') {
            setActiveButton('orders');
        } else if (location.pathname === '/invoices') {
            setActiveButton('invoices');
        }
    }, [location.pathname, setActiveButton]);


    const handleOrdersButtonClick = () => {
        setActiveButton('orders');
        navigate('/orders');
    };

    const handleInvoicesButtonClick = () => {
        setActiveButton('invoices');
        navigate('/invoices');
    };



    const handleLogout = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/auth/logout', {}, {
                withCredentials: true
            });

            if (response.status === 200) {
                dispatch(logout());
                purgeStoredState({ key: 'auth', storage });
                navigate('/login');
            }
        } catch (error) {
            console.error('There was a problem logging out:', error);
        }
    };


    useEffect(() => {
        if (token && !userInfo) {
            dispatch(fetchUserInfo(token));
        }
    }, [token, dispatch, userInfo]);


    return (
        <div className={styles.sidebar}>

            <div className={styles.desktop}>
                <div className={styles.top}>
                    <div className={styles.sidebarLogoContainer}>
                        <div className={styles.sidebarLogoInner}>
                            <img src={logo} alt="Wright Logo"/>
                        </div>
                    </div>

                    <div className={styles.sidebarUserInfo}>
                        {userInfo ? (
                            <>
                                <p>{userInfo.Customer_Name}</p>
                                <p>Customer ID: {userInfo.Customer_CustID}</p>
                            </>
                        ) : (
                            <p>Retrieving Customer Information...</p>
                        )}
                    </div>


                    <div className={styles.btnContainer}>
                        <button
                            className={activeButton === 'orders' ? styles.activeButton : ''}
                            onClick={handleOrdersButtonClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311L4.5 7.65311ZM12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM6.49896 9.97065L11 12.5765V17.625H13V12.5765L17.501 9.97066L16.499 8.2398L12 10.8445L7.50104 8.2398L6.49896 9.97065Z"/>
                            </svg>
                            Orders
                        </button>
                        <button
                            className={activeButton === 'invoices' ? styles.activeButton : ''}
                            onClick={handleInvoicesButtonClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M19 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V15H22V19C22 20.6569 20.6569 22 19 22ZM18 17V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V17H18ZM16 20V4H4V19C4 19.5523 4.44772 20 5 20H16ZM6 7H14V9H6V7ZM6 11H14V13H6V11ZM6 15H11V17H6V15Z"/>
                            </svg>
                            Invoices
                        </button>

                    </div>
                </div>
                <div className={styles.logOutContainer}>
                    <button className={styles.logOutButton}
                            onClick={handleLogout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M4 18H6V20H18V4H6V6H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V18ZM6 11H13V13H6V16L1 12L6 8V11Z"
                                fill="white"/>
                        </svg>
                        Logout
                    </button>
                </div>
            </div>

            <div className={styles.mobile}>
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="46" viewBox="0 0 49 46" fill="none">
                    <path
                        d="M36.5822 45.7333H12.4178C12.0939 45.2921 11.713 44.8866 11.4529 44.406C7.62521 37.3513 3.81428 30.2858 0 23.224C0 22.9864 0 22.747 0 22.5094C3.81428 15.4475 7.62521 8.38206 11.4529 1.32734C11.713 0.846781 12.0939 0.441255 12.4178 0C20.4726 0 28.5274 0 36.5822 0C36.854 0.375156 37.1712 0.721729 37.391 1.12904C41.2674 8.3106 45.132 15.5011 49 22.688V23.224C48.8171 23.5223 48.6208 23.8117 48.4546 24.119C44.8786 30.7432 41.311 37.3709 37.7266 43.988C37.3944 44.6007 36.9665 45.1527 36.5822 45.7315V45.7333ZM24.5067 4.98243C21.7093 4.98243 18.9137 5.00923 16.1163 4.96635C15.1917 4.95206 14.6094 5.3165 14.1563 6.16507C11.3891 11.3404 8.60185 16.5015 5.7961 21.6519C5.33966 22.4897 5.31113 23.2257 5.7726 24.0725C8.60353 29.2765 11.416 34.4911 14.2184 39.7148C14.6195 40.4633 15.1615 40.8581 15.9703 40.8581C21.5634 40.8509 27.1564 40.842 32.7495 40.8527C33.7529 40.8545 34.3839 40.4061 34.884 39.4647C37.6092 34.3339 40.3713 29.2229 43.1519 24.1261C43.6318 23.2454 43.615 22.5058 43.1401 21.634C40.3327 16.4837 37.5471 11.3208 34.785 6.14363C34.3436 5.31829 33.7848 4.96099 32.8988 4.97171C30.1031 5.00387 27.3058 4.98422 24.5084 4.98243H24.5067Z"
                        fill="#87C2DF"/>
                    <path
                        d="M18.8795 20.0763C18.8795 21.9905 18.8605 23.9064 18.8969 25.8206C18.9038 26.1445 19.0841 26.6653 19.2991 26.7332C19.5608 26.8168 20.0185 26.5974 20.2594 26.3692C21.2285 25.4565 22.2131 24.5456 23.0487 23.5162C23.4024 23.0825 23.5012 22.3667 23.5445 21.7658C23.6364 20.5222 23.7265 20.3654 24.9781 20.3671C28.0552 20.3706 27.6721 20.0867 27.7639 23.0999C27.8004 24.3 27.4675 25.1831 26.5765 26C25.0648 27.3881 23.6884 28.9261 22.1854 30.3247C21.7867 30.6975 21.1453 30.9605 20.5992 30.9901C19.0442 31.0772 17.4823 31.0023 15.9221 31.0267C15.1004 31.0388 14.6965 30.7306 14.7 29.8579C14.7138 24.6344 14.7138 19.4127 14.7 14.1891C14.6982 13.3566 15.071 13.0361 15.8614 13.0692C16.4092 13.0918 16.9588 13.0675 17.5066 13.0727C18.7305 13.0849 18.8657 13.212 18.8709 14.4156C18.8795 16.3019 18.8726 18.1882 18.8726 20.0728H18.8761L18.8795 20.0763Z"
                        fill="#87C2DF"/>
                    <path
                        d="M34.2969 22.0546C34.2969 24.6048 34.3051 27.1551 34.2937 29.7053C34.2888 30.849 34.122 31.0092 33.0574 31.0318C32.9216 31.0353 32.7859 31.0318 32.6485 31.0335C30.1792 31.0405 28.1171 30.0674 26.4622 28.1142C26.2708 27.8879 26.0664 27.4423 26.1547 27.2421C26.2528 27.0175 26.6731 26.8661 26.9642 26.8452C27.7508 26.7895 28.5439 26.786 29.3305 26.8278C30.1138 26.8696 30.3918 26.5179 30.3869 25.7032C30.3657 22.1677 30.3787 18.6322 30.3804 15.0966C30.3804 13.7446 31.0018 13.0681 32.2446 13.0669C33.615 13.0669 34.3002 13.8021 34.3002 15.2725C34.3002 17.5337 34.3002 19.7933 34.3002 22.0546H34.2969Z"
                        fill="#87C2DF"/>
                </svg>

                <div className={styles.iconsContainer}>

                    {!mobileMenu && (!searchBar ? (
                                <button onClick={() => setSearchBar(!searchBar)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                         fill="none">
                                        <path
                                            d="M18.031 17.4835L22.3137 21.7662L20.8995 23.1804L16.6168 18.8977C15.0769 20.1297 13.124 20.8667 11 20.8667C6.032 20.8667 2 16.8347 2 11.8667C2 6.8987 6.032 2.8667 11 2.8667C15.968 2.8667 20 6.8987 20 11.8667C20 13.9907 19.263 15.9436 18.031 17.4835ZM16.0247 16.7415C17.2475 15.4813 18 13.7623 18 11.8667C18 7.9992 14.8675 4.8667 11 4.8667C7.1325 4.8667 4 7.9992 4 11.8667C4 15.7342 7.1325 18.8667 11 18.8667C12.8956 18.8667 14.6146 18.1142 15.8748 16.8914L16.0247 16.7415Z"
                                            fill="#87C2DF"/>
                                    </svg>
                                </button>
                            ) : (
                                <button onClick={() => setSearchBar(!searchBar)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33"
                                         fill="none">
                                        <path
                                            d="M16.0009 14.982L22.6005 8.38232L24.4861 10.2679L17.8865 16.8676L24.4861 23.4672L22.6005 25.3528L16.0009 18.7532L9.40124 25.3528L7.51562 23.4672L14.1153 16.8676L7.51562 10.2679L9.40124 8.38232L16.0009 14.982Z"
                                            fill="#87C2DF"/>
                                    </svg>
                                </button>
                            )
                    )}


                    {/*{!mobileMenu && !searchBar && (*/}
                    {/*    <button>*/}
                    {/*        <img alt="" src={placeholder}/>*/}
                    {/*    </button>*/}
                    {/*)}*/}

                    {!searchBar && (
                        <Hamburger size={18} color="#87C2DF" toggled={mobileMenu} toggle={setMobileMenu}/>
                    )}

                </div>
            </div>

            <MobileMenu isOpen={mobileMenu} userInfo={userInfo} toggleMobileMenu={() => setMobileMenu(false)}/>
            <MobileSearchBar isOpen={searchBar} toggleSearchBar={() => setSearchBar(false)}/>
        </div>
    );
};

export default Sidebar;
