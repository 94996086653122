import React, { createContext, useContext, useState, ReactNode } from 'react';

type SearchContextType = {
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    selectedButton: "Open" | "" | "Closed" | "Aging";
    setSelectedButton: React.Dispatch<React.SetStateAction<"Open" | "" | "Closed" | "Aging">>;
};


const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
};


type SearchProviderProps = {
    children: ReactNode;
};

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedButton, setSelectedButton] = useState<"Open" | "" | "Closed" | "Aging">("");

    return (
        <SearchContext.Provider value={{ searchQuery, setSearchQuery, selectedButton, setSelectedButton }}>
            {children}
        </SearchContext.Provider>
    );
};
