import React from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import styles from './Layout.module.scss';
import {LayoutProps} from '../../typings/types';
import {SearchProvider} from "../utils/context/SearchContext";

const Layout: React.FC<LayoutProps> = ({ children }) => {

    return (
        <SearchProvider>
        <div className={styles.layout}>
            <Sidebar />
            {/*<Topbar />*/}
            {/*<Agingbar />*/}
            <div className={styles.content}>
                {children}
            </div>
        </div>
        </SearchProvider>
    );
};

export default Layout;
