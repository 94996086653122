import React from 'react';
import styles from './LoaderSmall.module.scss';

const LoaderSmall = () => {
    return (
        <div className={styles.loaderSmall}></div>
    );
};

export default LoaderSmall;
