import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './redux/Store';
import { validateUser } from './redux/AuthSlice';

interface InitializerProps {
    children: ReactNode;
}

const Initializer: React.FC<InitializerProps> = ({ children }) => {
    const token = useSelector((state: RootState) => state.auth.token);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (token) {
            dispatch(validateUser(token));
        }
    }, [dispatch, token]);

    return <>{children}</>;
};

export default Initializer;
