import React, {useMemo} from 'react';
import styles from './Pagination.module.scss';
import {renderPageNumbers} from "../../utils/functions/RenderPageNumbers";
import {useLocation} from "react-router-dom";

interface PaginationProps {
    totalPages: number;
    onPageChange: (newPage: number) => void;
    onPaginationClick: () => void;
}

const Pagination: React.FC<PaginationProps> = ({totalPages, onPageChange, onPaginationClick}) => {
    const location = useLocation();
    const currentPage = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('page') ? parseInt(queryParams.get('page')!, 10) : 1;
    }, [location.search]);

    return (
        <div className={styles.paginationContainer}>
            {currentPage !== 1 && (
            <button
                disabled={currentPage === 1}
                onClick={() => {
                    onPageChange(currentPage - 1);
                    onPaginationClick();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_827_4596)">
                        <path
                            d="M9.52344 9.99999L13.6484 5.87499L12.4701 4.69666L7.16677 9.99999L12.4701 15.3033L13.6484 14.125L9.52344 9.99999Z"
                            fill="#111111"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_827_4596">
                            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.5 0)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
            )}

            {renderPageNumbers(currentPage, totalPages).map(pageNum => (
                <button
                    key={pageNum}
                    disabled={pageNum === currentPage}
                    className={pageNum === currentPage ? styles.activePage : ""}
                    onClick={() => {
                        onPageChange(pageNum);
                        onPaginationClick();
                    }}
                >
                    {pageNum}
                </button>
            ))}

            {currentPage !== totalPages && (
            <button
                disabled={currentPage === totalPages}
                onClick={() => {
                    onPageChange(currentPage + 1);
                    onPaginationClick();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_827_4603)">
                        <path
                            d="M11.4767 9.99999L7.35168 5.87499L8.53002 4.69666L13.8334 9.99999L8.53002 15.3033L7.35168 14.125L11.4767 9.99999Z"
                            fill="#111111"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_827_4603">
                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
            )}
        </div>
    );
};

export default Pagination;
