import React, {useState} from 'react';
import styles from './Topbar.module.scss';
import {useActiveButton} from "../../utils/context/ActiveButtonContext";
import {TopBarProps} from "../../../typings/types";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";
import {useLocation, useNavigate} from "react-router-dom";


const Topbar: React.FC<TopBarProps> = ({search, setSearch}) => {
    const {activeButton} = useActiveButton();
    const [inputValue, setInputValue] = useState('');
    const location = useLocation();
    const navigate = useNavigate();


    function handleSearch(e: { preventDefault: () => void; }) {
        e.preventDefault();
        const queryParams = getUpdatedUrlParams(location.search, 'search', inputValue, true);

        if (inputValue.trim() === "") {
            navigate(`/${activeButton}`);
        } else {
            switch (activeButton) {
                case 'orders':
                    navigate(`/orders?${queryParams.toString()}`);
                    break;
                case 'invoices':
                    navigate(`/invoices?${queryParams.toString()}`);
                    break;
                default:
                    console.error(`Unsupported active button: ${activeButton}`);
            }
        }
    }



    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value);
    }



    return (
        <div className={styles.topbar}>
            <div className={styles.topbarInner}>
                <div className={styles.topbarSearchContainer}>
                    <form>
                        <button onClick={handleSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                    fill="#111111"/>
                            </svg>
                        </button>
                        <input placeholder={activeButton === 'orders' ? "Search Orders by Order Number or PO Number" : "Search Invoices by Order Number, Invoice Number, PO Number..."}
                               onChange={handleInputChange}
                               value={inputValue}
                        />
                    </form>
                </div>
                {/*<div className={styles.topbarProfileContainer}>*/}
                {/*    <img alt="" src={placeholder}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Topbar;
