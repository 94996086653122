import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axiosInstance from "../axios/AxiosConfig";
import {UserInfo} from '../../../typings/types';


interface AuthState {
    isAuthenticated: boolean;
    token: string | null;
    user: any;
    userInfo: UserInfo | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    token: null,
    user: null,
    userInfo: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{ token: string; user: any}>) => {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            state.user = null;
            state.userInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
        });
    }

});

export const validateUser = createAsyncThunk(
    'auth/validateUser',
    async (token: string) => {
        const response = await axiosInstance.post('/auth/validate', {}, {
            headers: {
                'Auth-Token': token
            }
        });
        return response.data;
    }
);

export const fetchUserInfo = createAsyncThunk(
    'auth/fetchUserInfo',
    async (token: string, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/orders/customer`, {
                headers: {
                    'Auth-Token': token
                }
            });

            if (response.status === 200 && response.data) {
                return response.data.value[0];
            }
        } catch (error) {
            console.log('Fetch User Info error', error);
        }
    }
);



export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
