import React from 'react';
import Layout from '../../layout/Layout';
import OrdersDataTable from "../datatables/Orders/OrdersDataTable";

const Orders: React.FC = () => {
    return (
        <Layout>
            <OrdersDataTable />
        </Layout>
    );
};

export default Orders;
