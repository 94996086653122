import React, {useEffect} from 'react';
import styles from './MobileMenu.module.scss';
import {useActiveButton} from "../../utils/context/ActiveButtonContext";
import {ButtonType, UserInfo} from "../../../typings/types";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {logout} from "../../utils/redux/AuthSlice";
import {purgeStoredState} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {useDispatch} from "react-redux";

interface MobileMenuProps {
    isOpen: boolean;
    toggleMobileMenu: () => void;
    userInfo: UserInfo | null;
}


const MobileMenu: React.FC<MobileMenuProps> = ({isOpen, toggleMobileMenu, userInfo}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Clean up function
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const {setActiveButton} = useActiveButton();

    const handleButtonClick = (buttonName: ButtonType) => {
        setActiveButton(buttonName);
        navigate('/' + buttonName)
        toggleMobileMenu();
    }

    const handleLogout = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/auth/logout', {}, {
                withCredentials: true
            });

            if (response.status === 200) {
                dispatch(logout());
                purgeStoredState({ key: 'auth', storage });
                navigate('/login');
            }
        } catch (error) {
            console.error('There was a problem logging out:', error);
        }
    };

    return (
        <div className={`${styles.MobileMenu} ${isOpen ? styles.open : ''}`}>
            <div className={styles.MobileMenuItems}>

                <div className={styles.MobileMenuCustomerInfo}>
                    {userInfo ? (
                        <>
                            <p>{userInfo.Customer_Name}</p>
                            <p>Customer ID: {userInfo.Customer_CustID}</p>
                        </>
                    ) : (
                        <p>Retrieving Customer Information...</p>
                    )}
                </div>


                <button className={styles.MobileMenuItem} onClick={() => handleButtonClick('orders')}>
                    <p>Orders</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                        <path
                            d="M17.5619 16.7343L10.9622 10.1346L12.8479 8.24902L21.3332 16.7343L12.8479 25.2195L10.9622 23.3339L17.5619 16.7343Z"
                            fill="white"/>
                    </svg>
                </button>
                <button className={styles.MobileMenuItem} onClick={() => handleButtonClick('invoices')}>
                    <p>Invoices</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                        <path
                            d="M17.5619 16.7343L10.9622 10.1346L12.8479 8.24902L21.3332 16.7343L12.8479 25.2195L10.9622 23.3339L17.5619 16.7343Z"
                            fill="white"/>
                    </svg>
                </button>
                <button className={styles.MobileMenuItem} onClick={handleLogout}>
                    <p>Logout</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M4 18H6V20H18V4H6V6H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V18ZM6 11H13V13H6V16L1 12L6 8V11Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default MobileMenu;


