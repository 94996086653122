export const renderPageNumbers = (currentPage: number, totalPageCount: number) => {
    let pages = [];

    if (totalPageCount <= 3) {
        pages = Array.from({ length: totalPageCount }, (_, i) => i + 1);
    } else {
        if (currentPage === 1) {
            pages = [1, 2, 3];
        } else if (currentPage === totalPageCount) {
            pages = [totalPageCount - 2, totalPageCount - 1, totalPageCount];
        } else {
            pages = [currentPage - 1, currentPage, currentPage + 1];
        }
    }

    return pages;
}
