import React from 'react';
import Topbar from "./topbar/Topbar";
import Agingbar from "./agingbar/Agingbar";
import {SearchHeaderProps} from "../../typings/types";

const SearchHeader: React.FC<SearchHeaderProps> = ({search, setSearch}) => {
    return (
        <>
            <Topbar search={search} setSearch={setSearch}/>
            <Agingbar/>
        </>
    )
};

export default SearchHeader
