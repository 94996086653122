export default (search:string, index : string | null = null, value : string = '', clear: string[] | boolean = false) => {
    let queryParams = new URLSearchParams(search);

    if(clear) {
        if(typeof clear === 'object') {
            for(let queryParam of clear) {
                queryParams.delete(queryParam);
            }
        } else {
            queryParams = new URLSearchParams();
        }
    }

    if(index) {
        queryParams.set(index, value);
    }

    return queryParams;
}
