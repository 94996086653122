import {InvoiceProps, OrderDetails, OrderProps} from "../../../typings/types";

export const transformOrderData = (data: any[]): OrderProps[] => {
    return data.map(order => ({
        Customer_CustID: order.Customer_CustID || "N/A",
        Customer_Name: order.Customer_Name || "N/A",
        OrderHed_OpenOrder: order.OrderHed_OpenOrder,
        OrderHed_PONum: order.OrderHed_PONum || "N/A",
        OrderHed_OrderDate: order.OrderHed_OrderDate ? order.OrderHed_OrderDate.split('T')[0] : "N/A",
        OrderHed_OrderNum: order.OrderHed_OrderNum || "N/A",
        Calculated_ShipCount: order.Calculated_ShipCount || "N/A",
        Calculated_TrackList: order.Calculated_TrackList ? order.Calculated_TrackList.replace(/,/g, ', ') : "N/A",
        Calculated_LineCount: order.Calculated_LineCount || "N/A",
        Calculated_OrdStatus: order.Calculated_OrdStatus || "N/A",
    }));
};


export const transformInvoiceData = (data: any[]): InvoiceProps[] => {
    return data.map(invoice => ({
        Customer_CustID: invoice.Customer_CustID || "N/A",
        Customer_Name: invoice.Customer_Name || "N/A",
        InvcHead_OpenInvoice: invoice.InvcHead_OpenInvoice || true,
        Calculated_Aging: invoice.Calculated_Aging || "N/A",
        InvcHead_InvoiceNum: invoice.InvcHead_InvoiceNum || "N/A",
        InvcHead_InvoiceDate: invoice.InvcHead_InvoiceDate ? invoice.InvcHead_InvoiceDate.split('T')[0] : "N/A",
        Terms_Description: invoice.Terms_Description || "N/A",
        InvcHead_DueDate: invoice.InvcHead_DueDate || "N/A",
        InvcHead_InvoiceAmt: invoice.InvcHead_InvoiceAmt || 0.00,
        InvcHead_InvoiceBal: invoice.InvcHead_InvoiceBal || 0.00,
        Calculated_LineCount: invoice.Calculated_LineCount || "N/A",
        InvcHead_OrderNum: invoice.InvcHead_OrderNum || "N/A",
        InvcHead_PONum: invoice.InvcHead_PONum || "N/A",
        RowIdent: invoice.RowIdent || "N/A",
        DownloadKey: invoice.DownloadKey || "N/A",
    }));
};

export const transformOrderDataDtl = (data: any[]): OrderDetails[] => {
    return data.map(order => ({
        Calculated_DueDateAdj: order.Calculated_DueDateAdj ? order.Calculated_DueDateAdj.split('T')[0] : "N/A",

        OrderHed_OrderDate: order.OrderHed_OrderDate ? order.OrderHed_OrderDate.split('T')[0] : "N/A",
        Customer_CustID: order.Customer_CustID || "N/A",
        Customer_Name: order.Customer_Name || "N/A",
        OrderHed_OpenOrder: order.OrderHed_OpenOrder || "N/A",
        OrderHed_PONum: order.OrderHed_PONum || "N/A",
        OrderHed_OrderNum: order.OrderHed_OrderNum || "N/A",
        Calculated_ShipCount: order.Calculated_ShipCount || "N/A",
        Calculated_TrackList: order.Calculated_TrackList ? order.Calculated_TrackList.replace(/,/g, ', ') : "N/A",
        Calculated_LineCount: order.Calculated_LineCount || "N/A",
        Calculated_OrdStatus: order.Calculated_OrdStatus || "N/A",
        Calculated_LineStatus: order.Calculated_LineStatus || "N/A",
        RowIdent: order.RowIdent || "N/A",
        TopOrderDtl_LineDesc: order.TopOrderDtl_LineDesc || "N/A",
        TopOrderDtl_OpenLine: order.TopOrderDtl_OpenLine || "N/A",
        TopOrderDtl_OrderLine: order.TopOrderDtl_OrderLine || "N/A",
        TopOrderDtl_OrderQty: order.TopOrderDtl_OrderQty || "N/A",
        TopOrderDtl_PartNum: order.TopOrderDtl_PartNum || "N/A",
        TopOrderDtl_XPartNum: order.TopOrderDtl_XPartNum || "N/A",

    }));
};

export const transformOrderDataRel = (data: any[]) => {
    return data.map(order => ({
        OrderHed_OrderDate: order.OrderHed_OrderDate ? order.OrderHed_OrderDate.split('T')[0] : "N/A",
        Customer_CustID: order.Customer_CustID || "N/A",
        Customer_Name: order.Customer_Name || "N/A",
        OrderHed_OpenOrder: order.OrderHed_OpenOrder || "N/A",
        OrderHed_PONum: order.OrderHed_PONum || "N/A",
        OrderHed_OrderNum: order.OrderHed_OrderNum || "N/A",
        Calculated_ShipCount: order.Calculated_ShipCount || "N/A",
        Calculated_TrackList: order.Calculated_TrackList ? order.Calculated_TrackList.replace(/,/g, ', ') : "N/A",
        Calculated_LineCount: order.Calculated_LineCount || "N/A",
        Calculated_OrdStatus: order.Calculated_OrdStatus || "N/A",
        TopOrderDtl_OpenLine: order.TopOrderDtl_OpenLine || "N/A",
        TopOrderDtl_OrderLine: order.TopOrderDtl_OrderLine || "N/A",
        TopOrderDtl_PartNum: order.TopOrderDtl_PartNum || "N/A",
        TopOrderDtl_XPartNum: order.TopOrderDtl_XPartNum || "N/A",
        TopOrderDtl_OrderQty: order.TopOrderDtl_OrderQty || "N/A",
        TopOrderDtl_LineDesc: order.TopOrderDtl_LineDesc || "N/A",
        Calculated_LineStatus: order.Calculated_LineStatus || "N/A",
        OrderRel_OpenRelease: order.OrderRel_OpenRelease == null ? "N/A" : order.OrderRel_OpenRelease,
        OrderRel_OrderRelNum: order.OrderRel_OrderRelNum || "N/A",
        OrderRel_ReqDate: order.OrderRel_ReqDate ? order.OrderRel_ReqDate.split('T')[0] : "N/A",
        OrderRel_SellingReqQty: order.OrderRel_SellingReqQty || "N/A",
        Calculated_RelStatus: order.Calculated_RelStatus || "N/A",
        Calculated_QtyShipped: order.Calculated_QtyShipped || "N/A",
        Calculated_DueDateAdj: order.Calculated_DueDateAdj ? order.Calculated_DueDateAdj.split('T')[0] : "N/A",
        RowIdent: order.RowIdent || "N/A"
    }));
};

