import React, {useMemo} from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {getStatus} from "../functions/statuses/GetStatusesOrdersRel";
import styles from "../../screens/datatables/OrdersSingle/OrdersSingle.module.scss";

function OrderRelTable({data}: any) {
    const columns = useMemo(() => [
        {
            header: 'Release Number',
            accessorKey: 'OrderRel_OrderRelNum'
        },
        {
            header: 'Release Date',
            accessorKey: 'OrderRel_ReqDate'
        },
        {
            header: 'Release Quantity',
            accessorKey: 'OrderRel_SellingReqQty'
        },
        {
            header: 'Due Date',
            accessorKey: 'Calculated_DueDateAdj'
        },
        {
            header: 'Status',
            accessorKey: 'Calculated_RelStatus',
            cell: (cellContext: any) => {
                const statusDetails = getStatus(cellContext.row.original.Calculated_RelStatus);
                return (
                    <div className={`${styles.statusContainer} ${styles[statusDetails.color]}`}>
                        <span className={styles.statusIndicator}></span>
                        <p>{statusDetails.text}</p>
                    </div>
                );
            }
        }
    ], []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {},
    });

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                        >
                            {header.isPlaceholder ? null : (
                                <div>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.mobileRow}>
                    {row.getVisibleCells().map((cell, index) => {
                        let className = '';
                        if (index === 1 || index === 2) {
                            className = styles.testing
                        }

                        return (
                            <td
                                key={index}
                                data-cell={cell.column.columnDef.header}
                                className={className}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        );
                    })}
                </tr>
            ))}

            </tbody>
        </table>
    );
}

export default OrderRelTable;
