import React, {useState} from 'react';
import './Login.css';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import arrow from '../../assets/images/arrow.svg';
import show from '../../assets/images/show-password.svg';
import hide from '../../assets/images/hide-password.svg';
import axiosInstance from '../../utils/axios/AxiosConfig';
import {useDispatch} from 'react-redux';
import {login} from '../../utils/redux/AuthSlice';
import LoaderSmall from "../../components/loaders/LoaderSmall";
import axios, { AxiosError } from 'axios';
import styles from "../datatables/Invoices/InvoicesDataTable.module.scss";


const Login = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showPasscodeForm, setShowPasscodeForm] = useState(false);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const [showCreateAccountForm, setShowCreateAccountForm] = useState(false);
    const [showCustomerVerificationForm, setShowCustomerVerificationForm] = useState(false);
    const [passcode, setPasscode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [createAccountFields, setCreateAccountFields] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);

        const emailElement = document.getElementById("email") as HTMLInputElement;
        const passwordElement = document.getElementById("password") as HTMLInputElement;

        const email = emailElement.value;
        const password = passwordElement.value;

        try {
            const response = await axiosInstance.post('/auth/login', {
                email,
                password,
            });


            if (response.status === 200) {
                dispatch(login({token: response.data.token, user: response.data.user}));
                navigate('/orders');
                setErrorMessage(null);
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Something went wrong.");
                }
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
        }
    };

    const handleForgot = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsLoading(true);

        const emailElement = document.getElementById("email") as HTMLInputElement;
        const email = emailElement.value;

        try {
            const response = await axiosInstance.post('/auth/forgot', {
                email,
            });

            if (response.status === 200) {
                // dispatch(login({ token: response.data.token, user: response.data.user }));
                setShowPasscodeForm(true);
                setErrorMessage('An email has been sent.');
                setIsLoading(false);
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Something went wrong.");
                }
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
        }
    };

    const handleValidatePasscode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const response = await axiosInstance.post('/auth/validateforgot', {
                code: passcode
            });

            if (response.status === 200) {
                setShowPasscodeForm(false);
                setShowResetPasswordForm(true);
                setIsLoading(false);
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Something went wrong.");
                }
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        e.preventDefault();

        const newPasswordError = testNewPassword();

        if(newPasswordError === '') {
            setErrorMessage(null);
        } else {
            setErrorMessage(newPasswordError);
            return;
        }

        try {
            const response = await axiosInstance.post('/auth/resetpassword', {
                code: passcode,
                password: newPassword
            });

            if (response.status === 200) {
                setIsLoading(false);
                dispatch(login({token: response.data.token, user: response.data.user}));
                navigate('/orders');
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Something went wrong.");
                }
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
        }
    };

    const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);

        const firstName = (document.getElementById("first-name") as HTMLInputElement).value;
        const lastName = (document.getElementById("last-name") as HTMLInputElement).value;
        const company = (document.getElementById("company") as HTMLInputElement).value;
        const email = (document.getElementById("email") as HTMLInputElement).value;
        const password = newPassword;

        const passwordError = testNewPassword();
        if (passwordError === '') {
            setErrorMessage(null);
        } else {
            setErrorMessage(passwordError);
            return;
        }

        setCreateAccountFields([firstName, lastName, company, email, password]);
        setShowCreateAccountForm(false);
        setShowCustomerVerificationForm(true);
    };

    const handleVerification = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        setShowSpinner(true);

        const customerId = (document.getElementById("customer-id") as HTMLInputElement).value;
        const invoiceOneNumber = (document.getElementById("invoice-one-number") as HTMLInputElement).value;
        const invoiceOneTotal = (document.getElementById("invoice-one-total") as HTMLInputElement).value;
        const invoiceTwoNumber = (document.getElementById("invoice-two-number") as HTMLInputElement).value;
        const invoiceTwoTotal = (document.getElementById("invoice-two-total") as HTMLInputElement).value;

        try {
            const validateResponse = await axiosInstance.post('/auth/validateAndCreateNewCustomer', {
                customer_id: customerId,
                invoice_one_number: invoiceOneNumber,
                invoice_one_total: invoiceOneTotal,
                invoice_two_number: invoiceTwoNumber,
                invoice_two_total: invoiceTwoTotal,
                first_name: createAccountFields[0],
                last_name: createAccountFields[1],
                company: createAccountFields[2],
                email: createAccountFields[3],
                password: createAccountFields[4],
            });

            if (validateResponse.status === 200) {
                setIsLoading(false);
                dispatch(login({token: validateResponse.data.token, user: validateResponse.data.user}));
                navigate('/orders');
            } else {
                setErrorMessage("Verification failed. Please check your details and try again.");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Something went wrong.");
                }
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
            setShowSpinner(false);
        }
    }

    function testNewPassword() {
        let passwordErrors = [];

        if (newPassword.length < 8) {
            passwordErrors.push("at least 8 characters");
        }

        // Check for uppercase letter
        if (!/[A-Z]/.test(newPassword)) {
            passwordErrors.push("1 uppercase letter");
        }

        // Check for lowercase letter
        if (!/[a-z]/.test(newPassword)) {
            passwordErrors.push("1 lowercase letter");
        }

        // Check for number
        if (!/\d/.test(newPassword)) {
            passwordErrors.push("1 number");
        }

        if (passwordErrors.length > 0) {
            let errorMessage = "Password must include " + passwordErrors.join(", ") + ".";
            setIsLoading(false);
            return errorMessage;
        }

        if (newPassword !== repeatPassword) {
            setIsLoading(false);
            return "Passwords do not match";
        }

        return '';
    }


    function ErrorMessage() {
        return (
            <div className="error-message-container">
                {errorMessage ? <p className="error-message">{errorMessage}</p> :
                    <p className="error-message-placeholder"></p>}
            </div>
        );
    }


    function clearInputs() {
        const inputs = document.querySelectorAll('input');
        inputs.forEach((input) => {
            input.value = '';
        });
    }

    return (
        <div className="login-wrapper">
            <div className="login-image-container">
                <div className="img-holder"></div>
            </div>


            {showPasscodeForm ? (
                <div className="login-form-container">
                    <div className="login-form-container">
                        <img src={logo} alt="Wright Logo" className="login-logo"/>
                        <p className="sign-in" style={{marginBottom: '12px'}}>Enter Passcode</p>
                        <form className="login-form" onSubmit={handleValidatePasscode}>
                            <input type="text" value={passcode} onChange={(e) => setPasscode(e.target.value)}
                                   placeholder="Passcode" required/>
                            <button type="submit" className="login-submit-btn">Validate Passcode</button>
                            <button onClick={() => {
                                setShowPasscodeForm(false);
                                setErrorMessage(null);
                            }}
                                    className="back-to-login">
                                <img alt="" src={arrow}/>
                                Back
                            </button>
                            <ErrorMessage  />
                        </form>
                    </div>

                    <div className="form-loader">
                        {isLoading && <LoaderSmall />}
                    </div>

                </div>

            ) : showResetPasswordForm ? (
                <div className="login-form-container">
                    <img src={logo} alt="Wright Logo" className="login-logo"/>
                    <p className="sign-in" style={{marginBottom: '12px'}}>Reset Password</p>
                    <form className="login-form" onSubmit={handleResetPassword}>
                        <div className="password-container">
                            <input type={showPassword ? "text" : "password"} value={newPassword}
                                   onChange={(e) => setNewPassword(e.target.value)}
                                   placeholder="New Password" required/>
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="show-password-btn"
                                tabIndex={-1}
                            >
                                <img src={showPassword ? hide : show} alt=""/>
                            </button>
                        </div>
                        <div className="password-container">
                            <input type={showConfirmPassword ? "text" : "password"} value={repeatPassword}
                                   onChange={(e) => setRepeatPassword(e.target.value)} placeholder="Repeat Password"
                                   required/>

                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="show-password-btn"
                                tabIndex={-1}
                            >
                                <img src={showConfirmPassword ? hide : show} alt=""/>
                            </button>
                        </div>
                        <button type="submit" className="login-submit-btn">Reset Password</button>
                        <ErrorMessage/>
                    </form>
                    <div className="form-loader">
                        {isLoading && <LoaderSmall/>}
                    </div>
                </div>
            ) : showForgotPassword ? (
                <div className="login-form-container">
                    <img src={logo} alt="Wright Logo" className="login-logo"/>
                    <p className="sign-in">Forgot Password?</p>
                    <p className="forgot-password-p">No worries, we’ll send you reset instructions.</p>
                    <form className="login-form" method="POST" action="" onSubmit={handleForgot}>
                        <input type="email" id="email" name="email" placeholder="Email" required autoComplete="email"/>
                        <button type="submit" className="login-submit-btn">Send Reset Link</button>
                        <button onClick={() => setShowForgotPassword(false)} className="back-to-login">
                            <img alt="" src={arrow}/>
                            Back to Login
                        </button>
                        <ErrorMessage  />
                    </form>
                    <div className="form-loader">
                        {isLoading && <LoaderSmall />}
                    </div>
                </div>
            ) : showCreateAccountForm ? (
                <div className="login-form-container">
                    <img src={logo} alt="Wright Logo" className="login-logo"/>
                    <form className="login-form" method="POST" action="" onSubmit={handleSignup}>
                        <p className="sign-in">Sign up</p>

                        <input type="text" id="first-name" name="first-name" placeholder="First name" required
                               autoComplete="given-name"/>
                        <input type="text" id="last-name" name="last-name" placeholder="Last name" required
                               autoComplete="family-name"/>
                        <input type="text" id="company" name="company" placeholder="Company" required
                               autoComplete="organization"/>
                        <input type="email" id="email" name="email" placeholder="Email" required autoComplete="email"/>
                        <div className="password-container">
                            <input type={showPassword ? "text" : "password"} id="create-password" name="create-password"
                                   placeholder="Create password" required autoComplete="new-password"
                                   onChange={(e) => setNewPassword(e.target.value)}/>
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="show-password-btn"
                                tabIndex={-1}
                            >
                                <img src={showPassword ? hide : show} alt=""/>
                            </button>
                        </div>
                        <div className="password-container">
                            <input type={showConfirmPassword ? "text" : "password"} id="confirm-password"
                                   name="confirm-password"
                                   placeholder="Confirm Password" required
                                   onChange={(e) => setRepeatPassword(e.target.value)}/>
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="show-password-btn"
                                tabIndex={-1}
                            >
                                <img src={showConfirmPassword ? hide : show} alt=""/>
                            </button>
                        </div>
                        <button type="submit" className="login-submit-btn">Next</button>
                        <div className="user-action-link text-return-to-login">
                            <p>Already have an account?</p>
                            <button
                                onClick={() => {
                                    setShowPassword(false);
                                    setShowConfirmPassword(false);
                                    setShowCreateAccountForm(false);
                                    setErrorMessage(null);
                                }}
                                className="user-action-link text-return-to-login-btn">
                                Log In
                            </button>
                        </div>
                        <ErrorMessage/>
                    </form>
                </div>
            ) : showCustomerVerificationForm ? (
                <div className="login-form-container">
                    <img src={logo} alt="Wright Logo" className="login-logo"/>
                    <p className="sign-in">Customer Verification</p>
                    <p className="user-action-link instructions">Please enter your Wright Tool customer ID as well as
                        your two most recent invoice numbers and dollar amount totals below.</p>
                    <form className="login-form" method="POST" action="" onSubmit={handleVerification}>
                        <input type="text" id="customer-id" name="customer-id" placeholder="Customer ID" required/>
                        <div className="invoice-row">
                            <div className="invoice-label-group">
                                <label htmlFor="invoice-one-number" className="user-action-link">Invoice 1</label>
                                <input type="text" id="invoice-one-number" name="invoice-one-number"
                                       placeholder="Invoice Number" required/>
                            </div>
                            <input type="text" id="invoice-one-total" name="invoice-one-total" placeholder="Total"
                                   required/>
                        </div>
                        <div className="invoice-row">
                            <div className="invoice-label-group">
                                <label htmlFor="invoice-two-number" className="user-action-link">Invoice 2</label>
                                <input type="text" id="invoice-two-number" name="invoice-two-number"
                                       placeholder="Invoice Number" required/>
                            </div>
                            <input type="text" id="invoice-two-total" name="invoice-two-total" placeholder="Total"
                                   required/>
                        </div>
                        <button type="submit" className="login-submit-btn">
                            {!showSpinner ?
                                <span>Sign up</span>
                                : <span className="spinner"></span>
                            }
                        </button>
                        <div className="user-action-link text-return-to-login">
                            <p>Already have an account?</p>
                            <button
                                onClick={() => {
                                    setShowCustomerVerificationForm(false);
                                    setErrorMessage(null);
                                }}
                                className="user-action-link text-return-to-login-btn">
                                Log In
                            </button>
                        </div>
                        <ErrorMessage/>
                    </form>
                </div>
            ) : (
                // Default Login screen
                <div className="login-form-container">
                    <img src={logo} alt="Wright Logo" className="login-logo"/>
                    <form className="login-form" method="POST" action="" onSubmit={handleLogin}>
                        <p className="sign-in">Sign In</p>
                        <input type="email" id="email" name="email" placeholder="Email" required autoComplete="email"/>
                        <div className="password-container">
                            <input type={showPassword ? "text" : "password"} id="password" name="password"
                                   placeholder="Password" required
                                   autoComplete="current-password"/>
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="show-password-btn"
                                tabIndex={-1}
                            >
                                <img src={showPassword ? hide : show} alt=""/>
                            </button>
                        </div>
                        <button type="submit" className="login-submit-btn">Login</button>
                        <button
                            onClick={() => {
                                setShowForgotPassword(true);
                                setErrorMessage(null);
                            }}
                            className="user-action-link">
                            Forgot your password?
                        </button>
                        <button
                            onClick={() => {
                                setShowCreateAccountForm(true);
                                setErrorMessage(null);
                                clearInputs();
                            }}
                            className="create-account user-action-link">
                            Create an account
                        </button>
                        <ErrorMessage/>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Login;
