import React, {useEffect, useState} from 'react';
import styles from './MobileSearchBar.module.scss';
import {useActiveButton} from "../../utils/context/ActiveButtonContext";
import {useSearch} from "../../utils/context/SearchContext";
import {useLocation, useNavigate} from "react-router-dom";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";

interface MobileSearchBarProps {
    isOpen: boolean;
    toggleSearchBar: () => void;
}

const MobileSearchBar: React.FC<MobileSearchBarProps> = ({isOpen, toggleSearchBar}) => {
    const {activeButton} = useActiveButton();
    const location = useLocation();
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    function handleSearch(e: { preventDefault: () => void; }) {
        e.preventDefault();
        const queryParams = getUpdatedUrlParams(location.search, 'search', inputValue, true);

        if (inputValue.trim() === "") {
            navigate(`/${activeButton}`);
        } else {
            switch (activeButton) {
                case 'orders':
                    navigate(`/orders?${queryParams.toString()}`);
                    break;
                case 'invoices':
                    navigate(`/invoices?${queryParams.toString()}`);
                    break;
                default:
                    console.error(`Unsupported active button: ${activeButton}`);
            }
        }
    }


    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value);
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && <div className={styles.overlay}></div>}
            <div className={`${styles.MobileSearchBar} ${isOpen ? styles.open : ''}`}>
                <form>
                    <div className={styles.inputWrapper}>
                        <input
                            placeholder={activeButton === 'orders' ? "Search Orders..." : "Search Invoices..."}
                            onChange={handleInputChange}
                            value={inputValue}

                        />
                        <button onClick={handleSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                 fill="none">
                                <path
                                    d="M18.031 17.3502L22.3137 21.6329L20.8995 23.0471L16.6168 18.7644C15.0769 19.9964 13.124 20.7334 11 20.7334C6.032 20.7334 2 16.7014 2 11.7334C2 6.7654 6.032 2.7334 11 2.7334C15.968 2.7334 20 6.7654 20 11.7334C20 13.8574 19.263 15.8103 18.031 17.3502ZM16.0247 16.6082C17.2475 15.348 18 13.629 18 11.7334C18 7.8659 14.8675 4.7334 11 4.7334C7.1325 4.7334 4 7.8659 4 11.7334C4 15.6009 7.1325 18.7334 11 18.7334C12.8956 18.7334 14.6146 17.9809 15.8748 16.7581L16.0247 16.6082Z"
                                    fill="#87C2DF"/>
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};


export default MobileSearchBar;
