import React, {useEffect, useMemo, useRef, useState} from 'react';
import InvoiceTableNav from "../../../components/tablenavs/InvoiceTableNav";
import styles from "./InvoicesDataTable.module.scss";
import useWindowSize from '../../../utils/functions/WindowResize';
import axiosInstance from "../../../utils/axios/AxiosConfig";
import {useSelector} from "react-redux";
import {RootState} from "../../../utils/redux/Store";
import {InvoiceDtlProps, invoiceOpenState, InvoiceProps} from "../../../../typings/types";
import Loader from "../../../components/loaders/Loader";
import LoaderSmall from "../../../components/loaders/LoaderSmall";
import Pagination from "../../../components/pagination/Pagination";
import InvoicesTable from "../../../utils/tables/InvoicesTable";
import InvoicesTableMobile from "../../../utils/tables/InvoicesTableMobile";
import {useLocation, useNavigate} from "react-router-dom";
import {transformInvoiceData,} from "../../../utils/functions/DataTransformers";
import SearchHeader from "../../../components/SearchHeader";
import getUpdatedUrlParams from "../../../utils/functions/GetUpdatedUrlParams";


const InvoicesDataTable = () => {
    // const searchDebounce = useRef<ReturnType<typeof setTimeout> | null>(null);


    //Helpers
    const token = useSelector((state: RootState) => state.auth.token);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const windowSize = useWindowSize();
    const location = useLocation();
    const navigate = useNavigate();

    const currentPage = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('page') ? parseInt(queryParams.get('page')!, 10) : 1;
    }, [location.search]);

    const selectedStatus = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('status') || '';
    }, [location.search]);

    const searchQuery = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('search') || '';
    }, [location.search]);

    const selectedButton = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('button') || '';
    }, [location.search]);

    //States
    const [invoiceData, setInvoiceData] = useState<InvoiceProps[]>([]);
    const [invoiceDtlData, setInvoiceDtlData] = useState<InvoiceDtlProps[]>([]);
    const [, setInvoiceNumbers] = useState<number[]>([]);

    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState<invoiceOpenState>({});

    const [searchError, setSearchError] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [search, setSearch] = useState<string>(searchQuery);

    //Pagination
    const [totalRecords, setTotalRecords] = useState(undefined);
    const recordsPerPage = 20;
    const totalPages = Math.ceil((totalRecords || 0) / recordsPerPage);


    const toggleInvoice = (id: string) => {
        const currentStatus = isOpen[id] || false;
        setIsOpen({
            ...isOpen,
            [id]: !currentStatus
        });
    };

    const handlePaginationClick = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };


    useEffect(() => {
        setLoading(true);
        setSearchError(null);
        setErrorMessage(null);
        const fetchInvoices = async () => {
            let filterParam = '';

            if (selectedStatus) {
                switch (selectedStatus) {
                    case 'Past Due':
                    case 'Aging':
                        filterParam = `&$filter=(Calculated_Aging eq 'Over 30' or Calculated_Aging eq 'Over 60' or Calculated_Aging eq 'Over 90' or Calculated_Aging eq 'Over 120')`;
                        break;
                    // case 'Over 60':
                    //     filterParam = `&$filter=(Calculated_Aging eq 'Over 60' or Calculated_Aging eq 'Over 90' or Calculated_Aging eq 'Over 120')`;
                    //     break;
                    // case 'Over 90':
                    //     filterParam = `&$filter=Calculated_Aging eq 'Over 90' or Calculated_Aging eq 'Over 120'`;
                    //     break;
                    // case 'Over 120':
                    //     filterParam = `&$filter=Calculated_Aging eq 'Over 120'`;
                    //     break;
                    default:
                        filterParam = `&$filter=Calculated_Aging eq '${selectedStatus}'`;
                        break;
                }
            }


            switch (selectedButton) {
                case 'Open':
                    filterParam += filterParam ? ' and InvcHead_OpenInvoice eq true' : '&$filter=InvcHead_OpenInvoice eq true';
                    break;
                case 'Closed':
                    filterParam += filterParam ? ' and InvcHead_OpenInvoice eq false' : '&$filter=InvcHead_OpenInvoice eq false';
                    break;
                // case 'Aging':
                //     const agingFilter = `(Calculated_Aging eq 'Over 30' or Calculated_Aging eq 'Over 60' or Calculated_Aging eq 'Over 90' or Calculated_Aging eq 'Over 120')`;
                //     filterParam += filterParam ? ` and ${agingFilter}` : `&$filter=${agingFilter}`;
                //     break;
                default:
                    break;
            }

            if (searchQuery) {
                const searchFilters = [
                    `InvcHead_InvoiceNum eq ${searchQuery}`,
                    `InvcHead_OrderNum eq ${searchQuery}`,
                    `InvcHead_PONum eq '${searchQuery}'`
                ];
                const combinedSearchFilter = searchFilters.join(' or ');
                filterParam += filterParam ? ` and (${combinedSearchFilter})` : `&$filter=(${combinedSearchFilter})`;
            }
            try {
                const response = await axiosInstance.get(`/invoices?$top=${recordsPerPage}&$skip=${(currentPage - 1) * recordsPerPage}${filterParam}&$count=true`, {
                    headers: {
                        'Auth-Token': token
                    }
                });

                if (response.status === 200) {
                    console.log(response.data.value);
                    setTotalRecords(response.data["@odata.count"]);
                    setInvoiceData(transformInvoiceData(response.data.value));
                    setInvoiceNumbers(response.data.value.map((invoice: { InvcHead_InvoiceNum: number; }) => invoice.InvcHead_InvoiceNum));
                    const currentInvoiceNumbers = response.data.value.map((invoice: { InvcHead_InvoiceNum: number; }) => invoice.InvcHead_InvoiceNum);
                    await fetchInvoiceDetailsForCurrentPage(currentInvoiceNumbers);
                }

                if (response.data.value.length === 0) {
                    setSearchError("No results found. ");
                }

            } catch (error) {
                console.error("Failed to fetch orders:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoices().catch(e => {
            console.error("Unhandled promise rejection in fetchOrder:", e);
        });
    }, [token, currentPage, searchQuery, selectedButton, selectedStatus]);

    const fetchInvoiceDetailsForCurrentPage = async (invoiceNumbers: any[]) => {
        try {
            const invoiceDetailPromises = invoiceNumbers.map(invoiceNum =>
                axiosInstance.get(`/invoices/details/${invoiceNum}`, {
                    headers: {
                        'Auth-Token': token
                    }
                })
            );

            const responses = await Promise.all(invoiceDetailPromises);

            const invoiceDetails = responses
                .filter(response => response.status === 200)
                .map(response => {
                    return response.data.value;
                });

            setInvoiceDtlData(invoiceDetails);

        } catch (error) {
            console.error("Failed to fetch invoice details:", error);
        }
    };

    const refreshData = () => {
        setSearchError(null);
        setErrorMessage(null);
        navigate('/invoices');
    };

    // const onSearch = useCallback(() => {
    //     if (searchDebounce.current) {
    //         clearTimeout(searchDebounce.current)
    //     }
    //
    //     searchDebounce.current = setTimeout(() => {
    //         const queryParams = getUpdatedUrlParams(location.search, 'search', search, ['page']);
    //         navigate(`?${queryParams.toString()}`, {replace: true});
    //     }, 500)
    //
    // }, [search])
    //
    // useEffect(() => {
    //     onSearch()
    // }, [onSearch])


    const isMobile = windowSize.width !== undefined && windowSize.width <= 768;

    return (
        <div className={styles.DataTable} ref={tableRef}>
            <SearchHeader search={search} setSearch={setSearch}/>
            <div className={styles.invoicesWrapper}>
                {loading ? (
                    isMobile ? <LoaderSmall/> : <Loader/>
                ) : searchError ? (
                    <div className={styles.error}>
                        {searchError}
                        <span>Click <button onClick={refreshData}>here</button> to reload.</span>
                    </div>
                ) : errorMessage ? (
                    <div className={styles.error}>
                        {errorMessage}
                        <span>Click <button onClick={refreshData}>here</button> to reload.</span>
                    </div>
                ) : (
                    <>
                        <InvoiceTableNav orderCount={totalRecords}/>

                        {isMobile ? (
                            invoiceData.map((invoice, index) => (
                                <InvoicesTableMobile
                                    key={index}
                                    invoice={invoice}
                                    isOpen={isOpen[invoice.RowIdent] || false}
                                    toggleOpen={() => toggleInvoice(invoice.RowIdent)}
                                    invoiceDtlData={invoiceDtlData}
                                />
                            ))
                        ) : (
                            <div className={styles.tableWrapper}>
                                <InvoicesTable data={invoiceData} detailsData={invoiceDtlData}
                                               recordsPerPage={recordsPerPage}/>
                            </div>
                        )}

                        <Pagination
                            totalPages={totalPages}
                            onPageChange={(newPage) => {
                                const queryParams = getUpdatedUrlParams(location.search, 'page', newPage.toString());
                                navigate(`?${queryParams.toString()}`, {replace: true});
                            }}
                            onPaginationClick={handlePaginationClick}
                        />

                    </>
                )}
            </div>
        </div>
    );


};

export default InvoicesDataTable;
