import React, {useEffect, useMemo, useState} from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import styles from "../../screens/datatables/Invoices/InvoicesDataTable.module.scss";
import download from '../../assets/images/download.svg';
import { DetailRow } from "./InvoicesDetailRows"
import axiosInstance from "../axios/AxiosConfig";
import {useSelector} from "react-redux";
import {RootState} from "../redux/Store";


function InvoicesTable({data, detailsData, recordsPerPage}: {data: any, detailsData: any, recordsPerPage: number}) {

    type OpenRowState = {
        [key: string]: boolean;
    };
    const [openRows, setOpenRows] = useState<OpenRowState>({});
    const [downloading, setDownloading] = useState('');
    const [finishedDownloading, setFinishedDownloading] = useState('');
    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        const id = downloading === '' ? finishedDownloading : downloading;
        const downloadIcon = document.getElementById(id+"-icon");
        const spinner = document.getElementById(id+"-spinner");
        if (downloadIcon && spinner) {
            if (downloading !== '') {
                downloadIcon.style.display = 'none';
                spinner.style.display = 'block';
            } else {
                downloadIcon.style.display = 'block';
                spinner.style.display = 'none';
            }
        }
    }, [downloading, finishedDownloading]);

    const handleRowClick = (rowId: string) => {
        setOpenRows(prevOpenRows => ({
            ...prevOpenRows,
            [rowId]: !prevOpenRows[rowId],
        }));
    };

    const handleDownloadClick = async (e: React.MouseEvent<HTMLButtonElement>, pdfKey: string, invoiceNum: number | string) => {
        e.stopPropagation();
        if (downloading !== invoiceNum.toString()) {
            setDownloading(invoiceNum.toString());
            await downloadPdf(pdfKey, invoiceNum);
            setDownloading('');
            setFinishedDownloading(invoiceNum.toString());
        }
    }

    const downloadPdf = async (pdfKey: string, invoiceNum: number | string) => {
        try {
            console.log("download pdf");
            const response = await axiosInstance.get(`/download/invoice/${invoiceNum}?x=${pdfKey}`, {
                headers: {
                    'Auth-Token': token
                },
                responseType: 'blob'
            });

            if (response.status !== 200) {
                console.error('Failed to download pdf');
                return;
            }

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoiceNum}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download pdf', error);
        }
    }



    const columns = useMemo(() => [
        {
            header: 'Invoice Number',
            accessorKey: 'InvcHead_InvoiceNum',
            cell: (cellContext: any) => {
                const invoiceNum = cellContext.row.original.InvcHead_InvoiceNum;
                const pdf = cellContext.row.original.DownloadKey;
                if (pdf) {
                    return (
                        <div className={styles.invoiceNumber}>
                            <div>{invoiceNum}</div>
                            <button className={styles.downloadButton}
                                    onClick={async (e) => {
                                        handleDownloadClick(e, pdf, invoiceNum);
                                    }}>
                                <img id={invoiceNum+"-icon"} src={download} alt={"Download PDF"}/>
                                <div id={invoiceNum+"-spinner"} className={styles.spinner} style={{display: 'none'}}></div>
                            </button>
                        </div>
                    );
                }
            }
        },
        {
            header: 'Due Date',
            accessorKey: 'InvcHead_InvoiceDate'
        },
        // {
        //     header: 'Terms',
        //     accessorKey: 'Terms_Description'
        // },
        {
            header: 'PO Num',
            accessorKey: 'InvcHead_PONum'
        },
        {
            header: 'Amount',
            accessorKey: 'InvcHead_InvoiceAmt',
            cell: (cellContext: any) => {
                return cellContext.row.original.InvcHead_InvoiceAmt.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
            }
        },
        {
            header: 'Balance',
            accessorKey: 'InvcHead_InvoiceBal',
            cell: (cellContext: any) => {
                return cellContext.row.original.InvcHead_InvoiceBal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
            }
        },
        {
            header: 'Aging',
            accessorKey: 'Calculated_Aging',
            cell: (cellContext: any) => {
                const isOver = cellContext.row.original.Calculated_Aging.includes('Over');
                return (
                    <span style={{ color: isOver ? 'red' : 'inherit' }}>
                    {cellContext.row.original.Calculated_Aging}
                </span>
                );
            }
        },
        // {
        //     header: 'Open',
        //     accessorKey: 'InvcHead_OpenInvoice',
        //     cell: (cellContext: any) => {
        //         const statusDetails = getStatus(cellContext.row.original.InvcHead_OpenInvoice);
        //         return (
        //             <div className={`${styles.statusContainer} ${styles[statusDetails.color]}`}>
        //                 <span className={styles.statusIndicator}></span>
        //                 <p>{statusDetails.text}</p>
        //             </div>
        //         );
        //     }
        // }
    ], []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {},
        initialState: {
            pagination: {
                pageSize: recordsPerPage,
            },
        },
    });

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    <th style={{width: '18px', padding: 0, paddingLeft: "18px"}}></th>
                    {headerGroup.headers.map(header => (
                        <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                        >
                            {header.isPlaceholder ? null : (
                                <div>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>

            {table.getRowModel().rows.map((row: any, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <tr key={rowIndex} onClick={() => handleRowClick(row.original.RowIdent)}
                        className={styles.mobileRow}>
                        <td style={{width: '18px', padding: 0, paddingLeft: "18px"}}
                            className={openRows[row.original.RowIdent]? styles.opened : ''}
                        >

                            <svg className={openRows[row.original.RowIdent] ? styles.rotated : ''}
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M13.1714 12.0003L8.22168 7.0505L9.63589 5.6363L15.9999 12.0003L9.63589 18.3643L8.22168 16.95L13.1714 12.0003Z"
                                    fill="black"/>
                            </svg>

                        </td>
                        {row.getVisibleCells().map((cell: any, index: number) => {
                            return (
                                <td
                                    key={index}
                                    data-cell={cell.column.columnDef.header}
                                    className={openRows[row.original.RowIdent] ? styles.opened : ''}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            );
                        })}
                    </tr>
                    {openRows[row.original.RowIdent] &&
                        <DetailRow
                            rowId={row.original.RowIdent}
                            rowIndex={rowIndex}
                            openRows={openRows}
                            detailsData={detailsData}
                            invoiceNumber={row.original.InvcHead_InvoiceNum}
                        />
                    }
                </React.Fragment>

            ))}

            </tbody>
        </table>
    );
}

export default InvoicesTable;
