import React, {ForwardedRef} from 'react';
import styles from './TableDropDown.module.scss';
import {getStatus} from '../../utils/functions/statuses/GetStatusesInvoices';
import {InvoiceTableDropDownProps} from "../../../typings/types";
import {useLocation, useNavigate} from "react-router-dom";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";



type ButtonType = "" | "Open" | "Closed";

const statusesForButton = {
    // "": ['Future', 'Current', 'Over 30', 'Over 60', 'Over 90', 'Over 120'],
    // Open: ['Future', 'Current', 'Over 30', 'Over 60', 'Over 90', 'Over 120'],
    "": ['Future', 'Current', 'Past Due'],
    Open: ['Future', 'Current', 'Past Due'],
    Closed: [],
    // Aging: ['Future', 'Current', 'Over 30', 'Over 60', 'Over 90']
};

const InvoiceTableDropDown = React.forwardRef<HTMLDivElement, InvoiceTableDropDownProps>(
    ({closeDropdown}, ref: ForwardedRef<HTMLDivElement>) => {

        const location = useLocation();
        const navigate = useNavigate();

        const handleStatusClick = (status: string) => {
            const queryParams = getUpdatedUrlParams(location.search, 'status', status, ['page']);
            navigate(`?${queryParams.toString()}`, { replace: true });
            closeDropdown();
        };

        const handleAllClick = () => {
            const queryParams = getUpdatedUrlParams(location.search, null, '', ['page', 'status', 'button']);
            navigate(`?${queryParams.toString()}`, { replace: true });
            closeDropdown();
        };

        const selectedStatus = new URLSearchParams(location.search).get('status') || '';
        const selectedButton = (new URLSearchParams(location.search).get('button') || '') as ButtonType;
        const filteredStatuses = statusesForButton[selectedButton] || [];


        return (
            <div className={styles.dropdownMenu} ref={ref}>
                <div className={styles.dropdownHeader} onClick={handleAllClick}>
                    All
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M9 12L4.5 7.5H13.5L9 12Z" fill="black"/>
                    </svg>
                </div>
                {filteredStatuses.map((status, r) => {
                    const statusDetails = getStatus(status);
                    return (
                        <div key={r}
                             className={`${styles.statusContainer} ${styles[statusDetails.color]} ${status === selectedStatus ? styles.selectedStatus : ''}`}
                             onClick={() => {
                                 handleStatusClick(status)
                             }}
                        >
                            <span className={styles.statusIndicator}></span>
                            {statusDetails.text}
                        </div>
                    )
                })}
            </div>
        );
    }
);

export default InvoiceTableDropDown;
