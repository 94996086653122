import React, {useMemo} from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import styles from "../../screens/datatables/OrdersSingle/OrdersSingle.module.scss";

function OrderShipmentDtlTable({data}: any) {
    const columns = useMemo(() => [
        {
            header: 'Line Number',
            accessorKey: 'ShipDtl_PackLine'
        },
        {
            header: 'Part Description',
            accessorKey: 'ShipDtl_LineDesc'
        },
        {
            header: 'Part Number',
            accessorKey: 'ShipDtl_PartNum'
        },
        {
            header: 'Release Number',
            accessorKey: 'ShipDtl_OrderRelNum'
        },
        {
            header: 'Quantity Shipped',
            accessorKey: 'Calculated_QtyShipped'
        }
    ], []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {},
    });

    return (
        <table>
            <thead className={styles.shipment}>
            <tr>
                {table.getHeaderGroups().map(headerGroup => (
                    headerGroup.headers.map(header => (
                        <th key={header.id}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                    ))
                ))}
            </tr>
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.shipment}>
                    {row.getVisibleCells().map((cell, index) => (
                        <td
                            key={index}
                            data-cell={cell.column.columnDef.header}
                            className={index === 1 ? styles.mobileDescription : ''}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default OrderShipmentDtlTable;
