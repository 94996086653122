import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes';
import {publicRoutes, privateRoutes} from './config/routes';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>

                {publicRoutes.map((route: { path: string; element: React.ReactElement }, r: React.Key) => (
                    <Route
                        key={r}
                        path={route.path}
                        element={route.element}
                    />
                ))}

                <Route element={<PrivateRoutes/>}>
                    {privateRoutes.map((route, r) => (
                        <Route
                            key={r}
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                </Route>

            </Routes>
        </BrowserRouter>
    );
};

export default Router;
