import React, {useMemo} from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, RowData,
    useReactTable
} from "@tanstack/react-table";
import styles from "../../screens/datatables/Orders/DataTable.module.scss";
import {getStatus} from "../functions/statuses/GetStatusesOrders";
import {OrderProps} from "../../../typings/types";
import {useNavigate} from "react-router-dom";

function OrderTable({data, recordsPerPage}: { data: any[], recordsPerPage: number }) {
    const navigate = useNavigate();

    const handleRowClick = (order: RowData) => {
        const castedOrder = order as OrderProps;
        navigate(`/orders/order/${castedOrder.OrderHed_OrderNum}`);
    }

    const columns = useMemo(() => [
        {
            header: 'Order Num',
            accessorKey: 'OrderHed_OrderNum'
        },
        {
            header: 'PO Num',
            accessorKey: 'OrderHed_PONum'
        },
        {
            header: 'Order Date',
            accessorKey: 'OrderHed_OrderDate'
        },
        {
            header: 'Tracking Number',
            accessorKey: 'Calculated_TrackList',
            cell: (cellContext: any) => {
                const trackList = cellContext.row.original.Calculated_TrackList;
                const formattedTrackList = trackList && trackList.length > 10 ? trackList.substring(0, 12) + '..' : trackList;
                return <div>{formattedTrackList}</div>;
            }
        },
        {
            header: 'Status',
            accessorKey: 'Calculated_OrdStatus',
            cell: (cellContext: any) => {
                const statusDetails = getStatus(cellContext.row.original.Calculated_OrdStatus);
                return (
                    <div className={`${styles.statusContainer} ${styles[statusDetails.color]}`}>
                        <span className={styles.statusIndicator}></span>
                        <p>{statusDetails.text}</p>
                    </div>
                );
            }
        }
    ], []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        initialState: {
            pagination: {
                pageSize: recordsPerPage,
            },
        },
        state: {},
    });

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                        >
                            {header.isPlaceholder ? null : (
                                <div>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} onClick={() => handleRowClick(row.original)} className={styles.mobileRow}>
                    {row.getVisibleCells().map((cell, index) => {
                        let className = '';
                        if (index === 1 || index === 2) {
                            className = styles.testing
                        }

                        return (
                            <td
                                key={index}
                                data-cell={cell.column.columnDef.header}
                                className={className}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        );
                    })}
                </tr>
            ))}

            </tbody>
        </table>
    );
}

export default OrderTable;
