import React, { createContext, useContext } from 'react';
import { ButtonType } from '../../../typings/types';

type ActiveButtonContextType = {
    activeButton: ButtonType;
    setActiveButton: React.Dispatch<React.SetStateAction<ButtonType>>;
};

export const ActiveButtonContext = createContext<ActiveButtonContextType | undefined>(undefined);

export const useActiveButton = () => {
    const context = useContext(ActiveButtonContext);
    if (!context) {
        throw new Error("useActiveButton must be used within a ActiveButtonProvider");
    }
    return context;
};
