import React, {ForwardedRef} from 'react';
import styles from './TableDropDown.module.scss';
import {getStatus} from '../../utils/functions/statuses/GetStatusesOrders';
import {TableDropDownProps} from "../../../typings/types";
import { useLocation, useNavigate } from "react-router-dom";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";



type ButtonType = "" | "Open" | "Closed";

const statusesForButton: Record<ButtonType, string[]> = {
    "": ['Open', 'Shipped', 'Partially Shipped', 'Invoiced', 'Partially Invoiced'],
    Open: ['Open', 'Partially Shipped', 'Partially Invoiced'],
    Closed: ['Shipped', 'Partially Invoiced', 'Invoiced']
};

const OrderTableDropDown = React.forwardRef<HTMLDivElement, TableDropDownProps>(
    ({closeDropdown}, ref: ForwardedRef<HTMLDivElement>) => {

        const location = useLocation();
        const navigate = useNavigate();

        const handleStatusClick = (status: string) => {
            const queryParams = getUpdatedUrlParams(location.search, 'status', status, ['page']);
            navigate(`?${queryParams.toString()}`, { replace: true });
            closeDropdown();
        };

        const selectedStatus = new URLSearchParams(location.search).get('status') || '';
        const selectedButton = (new URLSearchParams(location.search).get('button') || '') as ButtonType;
        const filteredStatuses = statusesForButton[selectedButton] || [];

        return (
            <div className={styles.dropdownMenu} ref={ref}>
                {filteredStatuses.map((status, r) => {
                    const statusDetails = getStatus(status);
                    return (
                        <div key={r}
                             className={`${styles.statusContainer} ${styles[statusDetails.color]} ${status === selectedStatus ? styles.selectedStatus : ''}`}
                             onClick={() => {
                                 handleStatusClick(status)
                             }}
                        >
                            <span className={styles.statusIndicator}></span>
                            {statusDetails.text}
                        </div>
                    )
                })}
            </div>
        );
    }
);

export default OrderTableDropDown;
