import styles from "../../screens/datatables/Invoices/InvoicesDataTable.module.scss";
import React, {useMemo} from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {InvoiceDetailRowProps} from "../../../typings/types";

export const DetailRow: React.FC<InvoiceDetailRowProps> = ({ rowId, openRows, detailsData: originalData, invoiceNumber }) => {


    const flattenedData = useMemo(() => originalData.flat(), [originalData]);
    const data = useMemo(() =>
            flattenedData.filter((item: { InvcHead_InvoiceNum: any; }) => item.InvcHead_InvoiceNum === invoiceNumber),
        [flattenedData, invoiceNumber]
    );


    const columns = useMemo(() => [
        {
            header: 'PO Number',
            accessorKey: 'InvcHead_PONum'
        },
        {
            header: 'Line Number',
            accessorKey: 'TopInvcDtl_InvoiceLine'
        },
        {
            header: 'Part Number',
            accessorKey: 'TopInvcDtl_PartNum'
        },
        {
            header: 'Release Number',
            accessorKey: 'TopInvcDtl_OrderRelNum',
        },
        {
            header: 'Quantity Shipped',
            accessorKey: 'TopInvcDtl_SellingShipQty',
        },
    ], []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {},
    });


    return (
        <tr className={`${styles.dropdownAnimation} ${openRows[rowId] ? styles.open : ''}`}>
            <td colSpan={7} className={styles.innerTD}>
                <table className={styles.innerTable}>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.getVisibleCells().map((cell, cellIndex) => (
                                <td key={cellIndex} data-cell={cell.column.columnDef.header}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </td>
        </tr>

    )
};
