import Login from '../screens/login/Login';
import Orders from '../screens/orders/Orders';
import Invoices from '../screens/invoices/Invoices';
import OrdersSingle from "../screens/single/OrdersSingle";

export const publicRoutes = [
    {
        path: '/',
        element: <Login />,
        exact: true,
    },
    {
        path: '/login',
        element: <Login />,
        exact: true,
    },
];

export const privateRoutes = [
    {
        path: '/orders',
        element: <Orders />,
        exact: true,
    },
    {
        path: '/orders/order/:orderNum',
        element: <OrdersSingle />,
    },
    {
        path: '/invoices',
        element: <Invoices />,
        exact: true,
    },
]
