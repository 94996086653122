import React from 'react';
import Layout from '../../layout/Layout';
import InvoicesDataTable from "../datatables/Invoices/InvoicesDataTable";

const Invoices: React.FC = () => {
    return (
        <Layout>
            <InvoicesDataTable />
        </Layout>
    );
};

export default Invoices;
