import React, { useEffect, useState } from 'react';
import styles from './Agingbar.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../utils/redux/Store";
import axiosInstance from "../../utils/axios/AxiosConfig";
import {useLocation, useNavigate} from "react-router-dom";
import getUpdatedUrlParams from "../../utils/functions/GetUpdatedUrlParams";

const Agingbar: React.FC = () => {
    const token = useSelector((state: RootState) => state.auth.token);
    const [agingCount, setAgingCount] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();


    const handleViewNowClick = () => {
        const queryParams = getUpdatedUrlParams(location.search, 'status', 'Aging', true);
        navigate(`/invoices?${queryParams.toString()}`);
    };



    const filterParam = `(Calculated_Aging eq 'Over 30' or Calculated_Aging eq 'Over 60' or Calculated_Aging eq 'Over 90' or Calculated_Aging eq 'Over 120')`;

    useEffect(() => {
        const fetchAgingInvoices = async () => {
            try {
                const response = await axiosInstance.get(`/invoices?$filter=${filterParam}&$count=true`, {
                    headers: {
                        'Auth-Token': token
                    }
                });

                if (response.status === 200 && response.data) {
                    setAgingCount(response.data['@odata.count']);
                }
            } catch (error) {
                console.error("Failed to fetch invoices:", error);
            }
        };

        fetchAgingInvoices().catch(e => {
            console.error("Unhandled promise rejection in fetchAgingInvoices:", e);
        });
    }, [token, filterParam]);


    if (agingCount <= 0) {
        return null;
    }

    return (
        <div className={styles.agingbar}>
            <div className={styles.agingbarInner}>
                <p>You have {agingCount} aging invoices</p>
                <div className={styles.agingbarProfileContainer}>
                    <button onClick={handleViewNowClick}>View Now</button>
                </div>
            </div>
        </div>
    );
};

export default Agingbar;
