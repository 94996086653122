import React from 'react';
import Layout from '../../layout/Layout';
import OrdersSingleDataTable from "../datatables/OrdersSingle/OrdersSingleDataTable";

const OrdersSingle: React.FC = () => {
    return (
        <Layout>
            <OrdersSingleDataTable />
        </Layout>
    );
};

export default OrdersSingle;
